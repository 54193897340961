import Marquee from "react-fast-marquee";

import { LINK_TREE_SOCIAL_URL } from "constants/global";

function Banner() {
  return (
    <div className="h-[45px] bg-gradient-to-r from-tertiary to-secondary text-18px font-light leading-[45px]">
      <Marquee>
        <p className="text-16px xl:text-18px max-w-container px-[60px] xl:px-[80px] 3xl:px-[140px]">
          Follow GPT Guru on all{" "}
          <a href={LINK_TREE_SOCIAL_URL} target="_blank" rel="noreferrer">
            <u>Social platforms</u>
          </a>{" "}
          to never miss any important update or opportunity to grow and innovate in Web 3.0 ⚡
        </p>
      </Marquee>
    </div>
  );
}

export default Banner;
