/* eslint-disable react/button-has-type */
import clsx from "clsx";

import Loader from "../loader/Loader";
import { IButtonProps } from "./IButton";

function Button({
  backgroundColor,
  onClick = () => {},
  variant = "primary",
  type = "button",
  fontSize = "",
  fontWeight = "400",
  color = "",
  className = "",
  showArrow = false,
  borderRadius = "0.32rem",
  prefix,
  suffix,
  rounded = false,
  width = "auto",
  height = "auto",
  borderColor = "",
  loading = false,
  contentClassName = "px-4 sm:px-10 py-3",
  disabled = false,
  name = "",
  children,
}: IButtonProps) {
  const mode = {
    primary: {
      className: "bg-gradient-to-r from-secondary to-tertiary text-white",
    },
    "primary-reverse": {
      className: "bg-gradient-to-r to-secondary from-tertiary text-black",
    },
    "primary-outline": {
      className: "bg-gradient-to-r from-tertiary to-secondary",
    },
    text: {
      className: "!px-0 !py-0 !bg-transparent",
    },
    secondary: {
      className: "border-primary border hover:bg-primary hover:text-white",
    },
    "primary-blue": {
      className: "bg-gradient-to-b from-primary-blue-500 to-primary-blue-700 text-white",
    },
    "white-outline": {
      className: "border border-white text-white hover:bg-white hover:text-black",
    },
  };

  return (
    <button
      name={name}
      type={type || "button"}
      className={clsx(
        "flex items-center p-[1px] disabled:cursor-not-allowed",
        mode[variant].className,
        showArrow && "pr-4",
        backgroundColor && "bg-none",

        className
      )}
      style={{
        backgroundColor,
        color,
        fontSize,
        fontWeight,
        borderRadius: rounded ? "100%" : borderRadius,
        width,
        height,
        borderColor,
      }}
      disabled={disabled || loading}
      onClick={e => {
        if (!loading) onClick(e);
      }}
    >
      <div
        className={clsx(
          "flex h-full w-full justify-center rounded-md",
          variant === "primary-outline" && "bg-black hover:bg-transparent transition-all  ease-in duration-300",
          contentClassName
        )}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            {prefix && <span className="mr-2">{prefix}</span>} <div className="w-full">{children}</div>
            {suffix && <span className="ml-2">{suffix}</span>}
          </>
        )}
      </div>
    </button>
  );
}

export default Button;
