import "./skeleton.css";

function Skeleton() {
  return (
    <div>
      <ul className="o-vertical-spacing o-vertical-spacing--l">
        <li className="blog-post o-media">
          <div className="o-media__figure">
            <span className="skeleton-box" style={{ width: 40, height: 40 }} />
          </div>
          <div className="o-media__body">
            <div className="o-vertical-spacing">
              <p>
                <span className="skeleton-box" style={{ width: "100%" }} />
                <span className="skeleton-box" style={{ width: "100%" }} />
                <span className="skeleton-box" style={{ width: "100%" }} />
                <span className="skeleton-box" style={{ width: "100%" }} />
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Skeleton;
