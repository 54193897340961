export default {
  receiver: {
    name: "receiver",
    requiredMessage: "Receiver Address is required",
    invalidMessage: "Receiver Address is invalid, Must starts with 0x and 40 characters long",
  },
  message: {
    name: "message",
    requiredMessage: "Message is required",
    invalidMessage: "Must be less than 200 characters",
  },
};
