import Button from "components/core-ui/button";

import useViewportWidth from "hooks/use-viewport";

function MessagingTabs({
  isActiveTab = false,
  setIsActiveTab,
}: {
  isActiveTab?: boolean;
  setIsActiveTab?: (value: boolean) => void;
}) {
  const isLaptopScreen = useViewportWidth() < 1600;

  return (
    <div className="flex justify-center mb-6">
      <div className="flex items-center gap-4">
        <div className="relative">
          <Button
            onClick={() => {
              if (!isActiveTab && setIsActiveTab) setIsActiveTab(true);
            }}
            height={isLaptopScreen ? 36 : 40}
            backgroundColor={isActiveTab ? "var(--color-success-500)" : "var(--color-primary-100-solid)"}
            contentClassName="px-4 sm:px-10 py-2"
            name="received"
          >
            Received
          </Button>
        </div>
        <Button
          onClick={() => {
            if (isActiveTab && setIsActiveTab) setIsActiveTab(false);
          }}
          height={isLaptopScreen ? 36 : 40}
          backgroundColor={!isActiveTab ? "var(--color-success-500)" : "var(--color-primary-100-solid)"}
          contentClassName="px-4 sm:px-10 py-2"
          name="sent"
        >
          Sent
        </Button>
      </div>
    </div>
  );
}

export default MessagingTabs;
