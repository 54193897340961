import { Link } from "react-router-dom";

import { clsx } from "clsx";

import { IAiModelItemProps } from "./IAiModelProps";

function AiModelItem({ id, title, icon, url, activeId, comingSoon, isNew }: IAiModelItemProps) {
  const handleClickLink = () => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return comingSoon ? (
    <div className="block w-full sm:w-[200px] lg:w-full xl:w-1/2 h-[120px] p-1">
      <div
        className={clsx(
          "relative h-full bg-tertiary-600 rounded-lg flex flex-col items-center justify-center gap-4",
          activeId === id && !comingSoon && "border-primary border",
          comingSoon && "cursor-default"
        )}
      >
        <img src={icon} alt={title} />
        <p> {title}</p>
        {comingSoon && (
          <span className="uppercase bg-warning-500 absolute right-0 top-0 font-medium text-10px px-2 py-1 rounded-sm">
            Coming SOON
          </span>
        )}
      </div>
    </div>
  ) : (
    <Link
      onClick={handleClickLink}
      key={id}
      className="block w-full sm:w-[200px] lg:w-full xl:w-1/2 h-[120px] p-1"
      to={`/ai-model/${url}`}
    >
      <div
        className={clsx(
          "relative h-full bg-tertiary-600 rounded-lg flex flex-col items-center justify-center gap-4",
          activeId === id && !comingSoon && "border-primary border",
          comingSoon && "cursor-default"
        )}
      >
        <img src={icon} alt={title} />
        <p> {title}</p>
        {isNew && (
          <span className="uppercase bg-success-500 absolute right-0 top-0 font-medium text-10px px-4 py-1 rounded-sm">
            NEW
          </span>
        )}
      </div>
    </Link>
  );
}

export default AiModelItem;
