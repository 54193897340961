/* eslint-disable jsx-a11y/control-has-associated-label */
import { clsx } from "clsx";
import {
  CMC_COMMUNITY,
  CMC_CURRENT_PRICE_LINK,
  DISCORD_CHANNEL,
  LINKEDIN_PROFILE,
  MEDIUM_PAGE,
  TELEGRAM_CHANNEL_LINK,
  TELEGRAM_GROUP_LINK,
  TWITTER_LINK,
  YOUTUBE_CHANNEL,
} from "constants/global";

import { ReactComponent as CMCIcon } from "../../assets/icons/cmc-icon.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedin-icon.svg";
import { ReactComponent as MediumIcon } from "../../assets/icons/medium-icon.svg";
import { ReactComponent as TelegramIcon } from "../../assets/icons/telegram-icon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube-icon.svg";
import { IISocialIconsProps } from "./IISocialIconsProps";
import RenderSocialWithChildren from "./social-icon-childen";

function SocialIcons({
  size = 50,
  bordered = true,
  gap = 16,
  className = "",
  isHorizontal = false,
}: IISocialIconsProps) {
  const icons = [
    {
      id: "twitter",
      icon: TwitterIcon,
      iconWidth: 22,
      iconHeight: 22,
      link: TWITTER_LINK,
    },
    {
      id: "telegram",
      icon: TelegramIcon,
      iconWidth: 22,
      iconHeight: 19,
      child: [
        { key: "telegram-channel", url: TELEGRAM_CHANNEL_LINK, title: "Telegram Announcement Channel" },
        { key: "telegram-group", url: TELEGRAM_GROUP_LINK, title: "Telegram Discussion Group" },
      ],
    },
    {
      id: "cmc",
      icon: CMCIcon,
      iconWidth: 30,
      iconHeight: 26,
      child: [
        { key: "cmc-current-price", url: CMC_CURRENT_PRICE_LINK, title: "$GPTG on CoinMarketCap" },
        {
          key: "cmc-community",
          url: CMC_COMMUNITY,
          title: "GPT Guru CoinMarketCap Community Page",
        },
      ],
    },
    {
      id: "linkedin",
      icon: LinkedinIcon,
      iconWidth: 25,
      iconHeight: 20,
      link: LINKEDIN_PROFILE,
    },
    {
      id: "medium",
      icon: MediumIcon,
      iconWidth: 25,
      iconHeight: 14,
      link: MEDIUM_PAGE,
    },
    {
      id: "youtube",
      icon: YoutubeIcon,
      iconWidth: 25,
      iconHeight: 14,
      link: YOUTUBE_CHANNEL,
    },
    {
      id: "discord",
      icon: DiscordIcon,
      iconWidth: 24,
      iconHeight: 18,
      link: DISCORD_CHANNEL,
    },
  ];

  return (
    <div className={clsx("flex items-center", className)} style={{ gap }}>
      {icons.map(({ id, icon: Icon, iconHeight, iconWidth, link, child }) => {
        return child ? (
          <RenderSocialWithChildren
            icon={Icon}
            iconHeight={iconHeight}
            iconWidth={iconWidth}
            child={child}
            id={id}
            key={id}
            bordered={bordered}
            size={size}
            isHorizontal={isHorizontal}
          />
        ) : (
          <a rel="noreferrer" target="_blank" href={link} key={id}>
            <button
              style={{ width: size, height: size }}
              className={clsx(
                "flex items-center justify-center",
                bordered &&
                  "border-primary border rounded-md hover:bg-gradient-to-br hover:from-tertiary hover:to-secondary path:hover:fill-white path:transition-all path:ease-out path:duration-150"
              )}
              type="button"
            >
              <Icon width={iconWidth} height={iconHeight} />
            </button>
          </a>
        );
      })}
    </div>
  );
}

export default SocialIcons;
