import { useMutation } from "react-query";

import axios from "axios";
import { API_URL, QUERY_KEYS } from "constants/api";

import Axios from "config/axios";

import { IGetSignedUrlBody } from "pages/ai-model/mint-nft/IMintNFT";

function useGetSignedUrl() {
  const getSignedUrl = (body: IGetSignedUrlBody) => {
    return Axios.post(API_URL.UPLOAD_SIGNED_URL, body);
  };

  const { mutate, isLoading, data, isError, error, isSuccess, reset, mutateAsync } = useMutation(
    QUERY_KEYS.GET_SIGNED_URL,
    getSignedUrl
  );

  const uploadFile = ({ url, file }: { url: string; file: Blob }) => axios.put(url, file);

  const {
    mutate: mutateUploadFile,
    isLoading: isFileUploadLoading,
    isError: isUploadFileError,
    mutateAsync: mutateUploadFileAsync,
  } = useMutation(QUERY_KEYS.UPLOAD_FILE, uploadFile);

  return {
    mutate,
    data,
    isLoading: isLoading || isFileUploadLoading,
    isError: isError || isUploadFileError,
    error,
    isSuccess,
    reset,
    mutateUploadFile,
    mutateAsync,
    mutateUploadFileAsync,
  };
}

export default useGetSignedUrl;
