import { useEffect, useState } from "react";
import { useMutation } from "react-query";

import clsx from "clsx";
import { API_URL, QUERY_KEYS } from "constants/api";
import Typewriter from "typewriter-effect";

import Axios from "config/axios";

import Button from "components/core-ui/button";
import CopyToClipboard from "components/core-ui/copy-to-clipboard/copy-to-clipboard";
import ErrorMessage from "components/core-ui/error-message/error-message";
import { Input } from "components/core-ui/input";
import DotLoader from "components/core-ui/loader/dot-loader";

import useUpdateCredits from "hooks/api/useUpdateCredits";

import GPTGuruChatIcon from "assets/icons/chatbot-icon.svg";
import CopyToClipboardIcon from "assets/icons/copy.svg";
import LightIcon from "assets/icons/light.svg";
import ChatBotImage from "assets/images/chat-bot.svg";

import { IAiModelProps } from "../IAiModel";

function ChatBot({ id }: IAiModelProps) {
  const [prompt, setPrompt] = useState("");
  const [concatenatedResponse, setConcatenatedResponse] = useState("");
  const [chatHistory, setChatHistory] = useState<{ key: string; text: string }[]>([]);

  const ideas = [
    {
      key: 1,
      text: "What is Web 3.0?",
    },
    {
      key: 2,
      text: "Difference between coin and token?",
    },
    {
      key: 3,
      text: "What is blockchain?",
    },
    {
      key: 4,
      text: "Which is better? Polygon or Ethereum.",
    },
  ];

  const getChat = () =>
    Axios.post(API_URL.CHAT, {
      query: prompt,
    });

  const { mutate, isLoading, data, isError, isSuccess, reset, error } = useMutation(QUERY_KEYS.CHAT, getChat);

  const handleSubmit = () => {
    setConcatenatedResponse("");
    mutate();
  };

  useEffect(() => {
    if (data?.data?.response) {
      setConcatenatedResponse(data.data.response);
    }
    if (isSuccess) {
      setChatHistory(prev => [...prev, { key: prompt, text: prompt }]);
      reset();
    }
  }, [data, isSuccess, prompt, reset]);

  useUpdateCredits(isSuccess, reset);

  return (
    <div id={id} className="flex gap-1">
      <div className="w-1/2 bg-primary-100 rounded-lg min-h-[650px]">
        <div className="flex items-center gap-6 px-8 py-6 border-b border-gray-800">
          <img src={GPTGuruChatIcon} alt="GPT Guru Chat" />
          <h2 className="font-medium text-20px">GPT Guru Chat</h2>
        </div>
        <div className="px-8 py-6 border-b border-gray-800">
          <div className="flex items-center gap-2 mb-3">
            <img src={LightIcon} alt="GPT Guru Chat" />
            <h2 className="font-medium text-gray-500">Bright Ideas</h2>
          </div>
          <ul className="flex flex-wrap gap-3">
            {ideas.map(({ key, text }) => (
              <li className="bg-primary-200 rounded-xl w-max" key={key}>
                <Button onClick={() => setPrompt(text)} contentClassName="px-6 py-4" variant="text">
                  {text}
                </Button>
              </li>
            ))}
          </ul>
        </div>
        {chatHistory && chatHistory.length > 0 && (
          <div className="px-8 py-6 mb-20">
            <ul className="flex flex-wrap gap-8 gap-y-4">
              {chatHistory.map(({ key, text }) => (
                <li
                  key={key}
                  className="relative z-10 bg-[#17172D] rounded-xl w-max before:h-5 before:w-5 before:bg-[#17172D] before:rotate-45 before:transform before:origin-bottom-left before:absolute before:top-0 before:-left-2"
                >
                  <Button onClick={() => setPrompt(text)} contentClassName="px-6 py-4" variant="text">
                    {text}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="p-2">
          <Input.TextArea value={prompt} onChange={e => setPrompt(e.target.value)} minHeight={150} />
          <div className={clsx("flex items-center my-8", isError ? "justify-between" : "justify-end")}>
            {isError && <ErrorMessage error={error} />}
            <Button
              onClick={handleSubmit}
              loading={isLoading}
              type="submit"
              width={140}
              backgroundColor="var(--color-primary)"
              disabled={!prompt}
            >
              Go Guru
            </Button>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "w-1/2 bg-tertiary-100 rounded-lg p-8 h-[650px]",
          !concatenatedResponse && "flex items-center justify-center"
        )}
      >
        {concatenatedResponse ? (
          <>
            <div className="w-full h-[520px] bg-primary-200 rounded-lg border-primary-200 border overflow-auto p-4 whitespace-pre-wrap mb-4">
              <Typewriter
                options={{
                  delay: 10,
                }}
                onInit={typewriter => {
                  typewriter.typeString(concatenatedResponse).start();
                }}
              />
            </div>
            {concatenatedResponse && (
              <div className="flex gap-2">
                <CopyToClipboard
                  text={concatenatedResponse}
                  prefix={<img className="w-7" src={CopyToClipboardIcon} alt="copy to clipboard" />}
                >
                  <span className="font-medium">Copy</span>
                </CopyToClipboard>
              </div>
            )}
          </>
        ) : (
          <div className="text-center">
            <img className="mx-auto mb-6" src={ChatBotImage} alt="" />
            <p className="mb-4">Hey! Good day! Let&apos;s talk!</p>

            {isLoading && (
              <>
                <DotLoader />
                <p className="text-12px animate-fade-in-out">It might take a while, please wait.</p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatBot;
