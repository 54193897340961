import React, { useEffect, useRef } from "react";

import { IVideoProps } from "./IVideo";

function Video({
  poster = "",
  videSource = "",
  loop = false,
  autoplay = false,
  muted = true,
  width = "100%",
  className = "",
}: IVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videSource]);

  return (
    <video
      className={className}
      ref={videoRef}
      width={width}
      poster={poster}
      autoPlay={autoplay}
      muted={muted}
      loop={loop}
    >
      <track kind="captions" />
      <source src={videSource} type="video/mp4" />
    </video>
  );
}

export default Video;
