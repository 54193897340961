import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { useUserContext } from "context/user-context";

export default function Example() {
  const modulesData = [
    {
      id: "ai-training",
      name: "AI Training",
    },
    {
      id: "ai-trading-indicator",
      name: "AI Trading Indicator",
    },
    {
      id: "smart-contracts",
      name: "Smart Contracts",
    },
    {
      id: "smart-contract-auditor",
      name: "Smart Contract Auditor",
    },
    {
      id: "chat-bot",
      name: "GPT Guru Chat",
    },
    {
      id: "sui-network",
      name: "SUI Network",
    },
    {
      id: "nft-marketplace",
      name: "NFT Marketplace",
    },
    {
      id: "mint-nft",
      name: "Mint NFT",
    },
    {
      id: "secret-messaging",
      name: "Secret Messaging",
    },
  ];

  const location = useLocation();

  const currentActive = location?.pathname?.split("/")?.[2];

  const { isUserLoggedIn } = useUserContext();
  const navigate = useNavigate();

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="group text-14px xl:text-16px font-medium lg:mt-2 xl:mt-3 hover:text-primary inline-flex w-full justify-center text-sm text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            Features
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-8 text-violet-200 group-hover:text-primary"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-10 left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              {modulesData.map(({ id, name }) => (
                <Menu.Item key={id}>
                  {() => (
                    <button
                      onClick={() => {
                        if (isUserLoggedIn) {
                          navigate(`/ai-model/${id}`);
                        } else {
                          navigate("/try");
                        }
                      }}
                      type="button"
                      className={`${
                        currentActive && currentActive === id
                          ? "bg-gradient-to-r to-tertiary from-secondary text-white"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm border-b border-gray-100 hover:bg-gradient-to-r from-tertiary to-secondary hover:text-white`}
                    >
                      {name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
