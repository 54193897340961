import { toast } from "react-toastify";

import axios from "axios";

import { API_BASE_URL } from "../constants/global";

const AccessTokenFromLocalStorage = localStorage.getItem("__ACCESS_TOKEN__");

const Axios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || API_BASE_URL,
});

Axios.interceptors.request.use(config => {
  const changedConfig = config;

  const token = AccessTokenFromLocalStorage;
  if (token && config.headers) {
    changedConfig.headers.Authorization = `Bearer ${token}`;
  }

  return changedConfig;
});

// DEFINING INTERCEPTORS
Axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const isBadRequest = Boolean(error?.response?.status === 400);
    const isUnAuthorized = Boolean(error?.response?.status === 403) || Boolean(error?.response?.status === 401);
    if (isUnAuthorized) {
      toast.error("Session expired, please login again");
      const accessToken = AccessTokenFromLocalStorage;
      if (isUnAuthorized && accessToken) {
        localStorage.removeItem("__ACCESS_TOKEN__");
        window.location.reload();
      }
    }
    if (isBadRequest) {
      toast.error(error?.response?.data?.message || "Bad request, Please try again");
    }

    return Promise.reject(error);
  }
);

export default Axios;
