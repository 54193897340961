import clsx from "clsx";

import useScrollPosition from "hooks/use-scroll-position";
import useViewportWidth from "hooks/use-viewport";

// # assets
import { ReactComponent as ArrowTop } from "assets/icons/arrow-top.svg";

import { IBackToTopProps } from "./IBackToTop";

function BackToTop({ offset = 400 }: IBackToTopProps) {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getScrollPosition = useScrollPosition();

  const isMobile = useViewportWidth() < 768;
  const scrollPosition = isMobile ? getScrollPosition * 2 : getScrollPosition;

  return (
    <button
      type="button"
      className={clsx(
        "fixed bottom-3 right-10 z-40 text-center cursor-pointer hidden animate-fade-in",
        scrollPosition > offset && "!inline-block"
      )}
      onClick={scrollToTop}
    >
      <div className="rounded-full border border-primary hover:bg-primary w-[50px] h-[50px] mx-auto flex items-center justify-center [&>svg>path]:hover:stroke-white">
        <ArrowTop className="w-[20px]" />
      </div>
      <p className="font-primary font-light text-12px mt-3">Back to top</p>
    </button>
  );
}

export default BackToTop;
