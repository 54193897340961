import { useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { clsx } from "clsx";
import { API_URL, QUERY_KEYS } from "constants/api";
import { EXPLORER_URL } from "constants/global";
import { ContractFactory } from "ethers";
import { Field, Form, Formik, FormikProps } from "formik";
import { useAccount, useBalance, useSigner } from "wagmi";
import { TypeBigNumber } from "web3/IWeb3";
import { toNumber } from "web3/utils";

import Axios from "config/axios";

import Button from "components/core-ui/button";
import CopyToClipboard from "components/core-ui/copy-to-clipboard/copy-to-clipboard";
import ErrorMessage from "components/core-ui/error-message/error-message";
import { Input } from "components/core-ui/input";

import { useUserContext } from "context/user-context";

import useGetSignedUrl from "hooks/api/use-get-singed-url";
import useLogin from "hooks/api/useLogin";
import useUpdateCredits from "hooks/api/useUpdateCredits";
import useViewportWidth from "hooks/use-viewport";

import MintNFTIcon from "assets/icons/mint-icon.svg";

import { truncateText } from "utils/truncate-text";

import { IAiModelProps } from "../IAiModel";
import AiModelMode from "../ai-model-mode";
import { IBodyGetGenerateArt, IDeployResponse, IMintFormValues } from "./IMintNFT";
import DefaultScreen from "./default-screen";
import formFields from "./form-fields";
import { formSchema } from "./form-schema";
import UploadNft from "./upload-nft";

// eslint-disable-next-line import/order
import GptGuruQuickNFTBatch from "web3/contracts/GptGuruQuickNFTBatch.json";

function NFTMarketplace({ id }: IAiModelProps) {
  const [isModeBasic, setIsModeBasic] = useState(true);
  const { data: signer } = useSigner();
  const { contractName, contractSymbol, file, promptText } = formFields;
  const [isDeploying, setIsDeploying] = useState(false);
  const [deployResponse, setDeployResponse] = useState<IDeployResponse | null>(null);
  const [selectedArtImage, setSelectedArtImage] = useState<string | null>(null);
  const [generatedArtImages, setGeneratedArtImages] = useState([]);
  const [filesForUpload, setFilesForUpload] = useState<File[]>([]);

  const formRef = useRef<FormikProps<IMintFormValues>>(null);

  const getArt = (body: IBodyGetGenerateArt) => Axios.post(API_URL.GENERATE_ART, body);
  const {
    mutate: mutateGenerateArt,
    isLoading: isLoadingGenerateArt,
    data: generateArtData,
    isSuccess: isSuccessGenerateArt,
    reset: isResetGenerateArt,
    isError: isErrorGenerateArt,
  } = useMutation(QUERY_KEYS.GENERATE_ART, getArt);

  useEffect(() => {
    if (isErrorGenerateArt) {
      toast.error("There is a problem generating AI Art. Please try again.");
    }
  }, [isErrorGenerateArt]);
  const { updateCredit } = useUpdateCredits(isSuccessGenerateArt, isResetGenerateArt);

  const { mutateUploadFile, isSuccess, reset, data, isLoading, isError, error, mutateAsync, mutateUploadFileAsync } =
    useGetSignedUrl();

  const { address } = useAccount();

  const { data: balanceObject } = useBalance({
    address,
  });

  const balance = useMemo(() => {
    if (balanceObject) {
      return toNumber(balanceObject?.value as TypeBigNumber);
    }
    return 0;
  }, [balanceObject]);

  const deployNft = async (urls: string[]) => {
    if (signer && !isError) {
      setIsDeploying(true);
      const nftDeployerInstance = new ContractFactory(GptGuruQuickNFTBatch.abi, GptGuruQuickNFTBatch.bytecode, signer);

      await nftDeployerInstance
        .deploy(formRef.current?.values?.contractName, formRef.current?.values?.contractSymbol, urls)
        .then(async nft => {
          await nft
            .deployed()
            .then(res => {
              setDeployResponse({ contract: res.address, transactionHash: res.deployTransaction.hash });
              updateCredit();
              setFilesForUpload([]);
              setGeneratedArtImages([]);
              setSelectedArtImage(null);
            })
            .catch(() => {
              setIsDeploying(false);
            })
            .finally(() => setIsDeploying(false));
        })
        .catch(() => {
          setIsDeploying(false);
        });
    } else if (isError) toast.error("There is a problem in uploading a file. Please try again");
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
    }

    if (generateArtData && generateArtData?.data?.images && generateArtData?.data?.images.length > 0) {
      setGeneratedArtImages(generateArtData?.data?.images);
      setSelectedArtImage(generateArtData?.data?.images[0]?.uri);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess, mutateUploadFile, reset, signer, generateArtData]);

  const generateAiArt = () =>
    mutateGenerateArt({ prompt: formRef.current?.values?.promptText || "", numberOfImages: 4 });

  const { isCorrectChain, switchNetworkManually } = useLogin();

  const isLaptopScreen = useViewportWidth() < 1600;
  const isSmallMobileScreen = useViewportWidth() < 480;

  const { currentUser } = useUserContext();

  return (
    <>
      <AiModelMode
        basicTitle={isSmallMobileScreen ? "AI" : "AI Mint"}
        advancedTitle={isSmallMobileScreen ? "Personalized" : "Personalized Mint"}
        isModeBasic={isModeBasic}
        setIsModeBasic={setIsModeBasic}
      />
      <div id={id} className="xl:flex gap-2">
        <div className="w-full xl:w-1/2">
          <div className="px-6">
            <div className="flex items-center gap-3">
              <img src={MintNFTIcon} alt="Mint NFT" /> <h2 className="text-20px font-medium text-primary">Mint NFTs</h2>
            </div>
            <p>With Guru AI, you can Mint NFT across any network way fast and easily like never before.</p>
          </div>

          <div className="rounded-lg bg-primary-100 px-6 py-6 flex flex-col gap-8">
            <Formik
              innerRef={formRef}
              initialValues={{
                file: {} as File,
                contractName: "",
                contractSymbol: "",
                promptText: "",
              }}
              validationSchema={formSchema}
              onSubmit={async () => {
                if (balance <= 0) {
                  toast.error("You don't have enough balance to mint NFT.");
                  return;
                }

                if (currentUser && currentUser?.credits <= 0) {
                  toast.error("You don't have enough credits to mint NFT.");
                  return;
                }

                setDeployResponse(null);

                if (isCorrectChain) {
                  if (isModeBasic && selectedArtImage) {
                    deployNft([selectedArtImage]);
                    return;
                  }
                  const fileForUploadPromise = filesForUpload.map(async fileForUpload => {
                    try {
                      const d = await mutateAsync({
                        filename: fileForUpload.name,
                      });
                      return d?.data?.data;
                    } catch (err) {
                      return [];
                    }
                  });

                  const fileForUploadResponse = await Promise.all(fileForUploadPromise);

                  const uploadFileToS3Promises = fileForUploadResponse.map(async (fileForUpload, index) => {
                    try {
                      await mutateUploadFileAsync({
                        url: fileForUpload,
                        file: filesForUpload[index],
                      });

                      return fileForUploadResponse[index]?.split("?")[0];
                    } catch (err) {
                      return [];
                    }
                  });

                  const uploadFileToS3PromisesResponse = await Promise.all(uploadFileToS3Promises);

                  deployNft(uploadFileToS3PromisesResponse);

                  return;
                }
                toast.info("Please switch to the correct network.");
                switchNetworkManually();
              }}
            >
              {({ setFieldValue, errors, touched }) => {
                // eslint-disable-next-line no-lone-blocks

                return (
                  <Form>
                    {isModeBasic && (
                      <div className="mb-4">
                        <div className="flex gap-4">
                          <Field className="flex-1" label="Prompt text" name={promptText.name} as={Input} />

                          <Button
                            loading={isLoadingGenerateArt}
                            variant="primary-outline"
                            className="mt-8"
                            height={isLaptopScreen ? 51 : 58}
                            contentClassName="px-4 sm:px-10 py-4"
                            onClick={generateAiArt}
                          >
                            Generate AI Art
                          </Button>
                        </div>
                      </div>
                    )}

                    {!isModeBasic && (
                      <UploadNft
                        files={filesForUpload}
                        setFiles={setFilesForUpload}
                        error={errors.file ? (errors.file as string[]) : []}
                        name={file.name}
                        previewFile={formRef.current?.values?.file}
                        onChange={(acceptedFiles: File[]) => {
                          const filteredAcceptedFiles = acceptedFiles.filter(
                            fileForUpload => !filesForUpload.some(f => f.name === fileForUpload.name)
                          );

                          const filterFilesIfMoreThan10 = filteredAcceptedFiles.slice(0, 10 - filesForUpload.length);

                          setFilesForUpload([...filesForUpload, ...filterFilesIfMoreThan10]);

                          setFieldValue("file", [...filesForUpload, ...filterFilesIfMoreThan10]);
                        }}
                      />
                    )}
                    <Field
                      error={errors.contractName && touched.contractName ? errors.contractName : null}
                      className="mb-4"
                      label="Contract name"
                      as={Input}
                      name={contractName.name}
                      disabled={isModeBasic ? !selectedArtImage : !filesForUpload.length}
                    />
                    <Field
                      error={errors.contractSymbol && touched.contractSymbol ? errors.contractSymbol : null}
                      className="mb-4"
                      label="Contract symbol"
                      as={Input}
                      name={contractSymbol.name}
                      disabled={isModeBasic ? !selectedArtImage : !filesForUpload.length}
                    />

                    <div className={clsx("flex items-center", isError ? "justify-between" : "justify-end")}>
                      {isError && <ErrorMessage error={error} />}
                      <Button
                        disabled={isLoading || isDeploying || (isModeBasic && !selectedArtImage)}
                        loading={isLoading || isDeploying}
                        type="submit"
                        backgroundColor="var(--color-primary)"
                      >
                        {isCorrectChain ? "Mint NFT" : "Switch Network"}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="flex-1 lg:w-full xl:w-1/2 bg-tertiary-100 py-8 px-6 rounded-lg">
          {deployResponse && (
            <div className="text-center">
              <div>
                <p className="font-medium text-30px mb-6">
                  Your <span className="text-primary">NFT</span> is Minted
                </p>
                <div className="text-primary text-20px font-medium">Contract Address</div>
                <CopyToClipboard
                  text="deployResponse?.contract"
                  backgroundColor="transparent"
                  contentClassName=""
                  buttonClassName="mx-auto"
                >
                  <p className="mb-4 mx-auto">{truncateText(deployResponse?.contract, 24, true)}</p>
                </CopyToClipboard>
                <div className="text-primary text-20px font-medium">Transaction hash</div>
                <a
                  href={`${EXPLORER_URL}/${deployResponse?.transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  <p>{truncateText(deployResponse?.transactionHash, 24, true)}</p>
                </a>
              </div>
            </div>
          )}

          {generatedArtImages && generatedArtImages.length > 0 && isModeBasic && (
            <div className="grid md:grid-cols-2 gap-4 mt-8">
              {generatedArtImages.map((image: { uri: string }) => (
                <Button
                  variant="text"
                  key={image.uri}
                  onClick={() => {
                    setSelectedArtImage(image.uri);
                  }}
                  className={clsx(selectedArtImage === image.uri && "border border-primary")}
                  contentClassName="px-6"
                >
                  <img className="w-full object-cover object-top my-6 rounded-md" src={image.uri} alt="" />
                </Button>
              ))}
            </div>
          )}

          {!deployResponse && (!selectedArtImage || !isModeBasic) && (
            <DefaultScreen isLoading={isLoading || isDeploying} className="my-32" />
          )}
        </div>
      </div>
    </>
  );
}

export default NFTMarketplace;
