import { Outlet } from "react-router-dom";

import useScrollToTop from "hooks/use-scroll-to-top";

import WithSuspense from "routes/with-suspense";

import Footer from "./footer";
import Header from "./header";

function Layout() {
  useScrollToTop();

  return (
    <>
      <Header />

      <WithSuspense>
        <div className="animate-fade-in">
          <Outlet />
        </div>
      </WithSuspense>

      <Footer />
    </>
  );
}

export default Layout;
