import * as yup from "yup";

import formFields from "./form-fields";

const { q1, q2, q3, promptText } = formFields;

export const formSchema = {
  basic: yup.object().shape({
    [q1.name]: yup.string().required(q1.requiredMessage),
    [q2.name]: yup.string().required(q2.requiredMessage),
    [q3.name]: yup.string().required(q3.requiredMessage),
  }),
  advanced: yup.object().shape({
    [promptText.name]: yup.string().required(promptText.requiredMessage),
  }),
};
