import { clsx } from "clsx";

import DotLoader from "components/core-ui/loader/dot-loader";

import NodeIcon from "assets/icons/nodes-icon.svg";
import ChainLogos from "assets/images/chains-logo.png";

import { IDefaultScreenProps } from "./IAiModelProps";

function DefaultScreen({ description, className, isLoading }: IDefaultScreenProps) {
  return (
    <div className={clsx("text-center", className)}>
      <img className="mx-auto mb-6" src={NodeIcon} alt="" />
      <p className="mb-8">{description}</p>

      {isLoading ? (
        <>
          <DotLoader />
          <p className="text-12px animate-fade-in-out mt-2">It might take a while, please wait.</p>
        </>
      ) : (
        <>
          <p className="mb-4">Supported Networks</p>
          <img className="mx-auto" src={ChainLogos} alt="" />
        </>
      )}
    </div>
  );
}

export default DefaultScreen;
