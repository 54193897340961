import { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";

import Button from "components/core-ui/button";

import CloseIcon from "assets/icons/close-icon.svg";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  file: File | null;
}

function ImagePreviewModal({ isOpen, onClose, file }: IProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="max-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          <span className="inline-block align-middle h-screen overflow-auto" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="max-h-[80vh] inline-block overflow-hidden text-left align-middle transition-all transform bg-black text-white shadow-xl rounded-sm w-full lg:w-[880px] py-[60px] px-[12px] md:px-[20px] lg:px-[40px] border border-gray-600 ">
              {file && <img className="w-full overflow-auto object-none" src={URL.createObjectURL(file)} alt="" />}
              <Button onClick={onClose} className="absolute right-0 top-0" contentClassName="px-4 py-6" variant="text">
                <img src={CloseIcon} alt="close modal" />
              </Button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ImagePreviewModal;
