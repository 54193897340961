import { Link, useLocation } from "react-router-dom";

import { CONTACT_US_EMAIL, MEDIA_KIT_URL, PITCH_DECK_LINK, WHITEPAPER_LINK } from "constants/global";

import AnchorLink from "components/core-ui/anchor-link";
import SocialIcons from "components/social-icons";

import useViewportWidth from "hooks/use-viewport";

import BuyGptGModal from "pages/home/components/buy-gptg-modal";
import Subscribe from "pages/home/components/subscribe";

import Logo from "assets/images/logo-dark.png";

function Footer() {
  const isMobile = useViewportWidth() < 480;
  const location = useLocation();
  const handleWallet = location.pathname === "/app-privacy-policy";

  return (
    !handleWallet && (
      <>
        <Subscribe />
        <div className="mt-12 sm:mt-20 lg:mt-36">
          <div className="max-w-container px-[16px] sm:px-[24px] lg:px-[80px] 3xl:px-[240px]">
            <div className="lg:grid lg:grid-cols-12 justify-between">
              <div className="col-span-5 text-center lg:text-start  mb-12 lg:mb-0">
                <img className="mb-8 mx-auto lg:mx-0" src={Logo} alt="GPTG" />

                <p className="text-16px lg:text-20px light mb-4 font-light ">
                  GPT Guru is a next-generation blockchain AI tool empowering Web 3.0 communities fuelling their
                  creativity and productivity like never before !🤖👨‍💻
                </p>
                <p>
                  For contact and queries, please contact{" "}
                  <AnchorLink href="mailto:connect@gptguru.io" className="text-primary">
                    connect@gptguru.io
                  </AnchorLink>
                </p>
              </div>

              <div className="col-span-7 lg:ps-8 xl:ps-36 flex flex-col sm:flex-row gap-12 sm:gap-32 lg:gap-20 xl:gap-32 justify-center lg:justify-between whitespace-nowrap flex-wrap lg:flex-nowrap">
                <div className="flex flex-col items-center gap-6">
                  <h3 className="font-medium text-20px lg:text-26px">GPT Guru</h3>

                  <AnchorLink className="text-16px lg:text-20px hover:text-primary d-block" href={WHITEPAPER_LINK}>
                    Whitepaper
                  </AnchorLink>
                  <AnchorLink href={MEDIA_KIT_URL} className="text-16px lg:text-20px hover:text-primary">
                    Media Kit
                  </AnchorLink>
                  <Link to="/privacy-policy" className="text-16px lg:text-20px hover:text-primary">
                    Privacy policy
                  </Link>
                </div>
                <div className="flex flex-col items-center gap-6">
                  <h3 className="font-medium text-20px lg:text-26px">Investors</h3>

                  <AnchorLink href={PITCH_DECK_LINK} className="text-16px lg:text-20px hover:text-primary">
                    Pitch Deck
                  </AnchorLink>

                  <a href={`mailto:${CONTACT_US_EMAIL}`} className="text-16px lg:text-20px hover:text-primary ">
                    Contact Us
                  </a>
                </div>
                <div className="flex flex-col items-center gap-6">
                  <h3 className="font-medium text-20px lg:text-26px">Pricing</h3>
                  <BuyGptGModal variant="text" contentClassName="text-20px" />
                  <Link to="/staking" className="text-16px lg:text-20px hover:text-primary">
                    Stake $GPTG
                  </Link>
                  <Link to="/try" className="text-16px lg:text-20px hover:text-primary">
                    Guru AI Experience
                  </Link>
                </div>
              </div>
            </div>

            <hr className="my-8 border-gray-700" />

            <div className="text-center gap-4 lg:gap-0 lg:text-start flex flex-col lg:flex-row items-center justify-center">
              <SocialIcons
                gap={isMobile ? 10 : 16}
                isHorizontal
                className="flex justify-center lg:justify-end"
                size={isMobile ? 36 : 42}
              />
            </div>
          </div>
          <p className="text-16px lg:text-20px bg-gradient-to-r from-tertiary to-secondary my-10 rounded-lg text-center py-2">
            Copyrights © 2023. GPT Gurus OÜ.
            <br className="lg:hidden" />
            All Rights Reserved!
          </p>
        </div>
      </>
    )
  );
}

export default Footer;
