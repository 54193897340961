import Button from "components/core-ui/button";

import useViewportWidth from "hooks/use-viewport";

import { IAiModelModeProps } from "./IAiModel";

function AiModelMode({
  isModeBasic,
  setIsModeBasic,
  basicTitle = "Basic",
  advancedTitle = "Advanced",
}: IAiModelModeProps) {
  const isLaptopScreen = useViewportWidth() < 1600;

  return (
    <div className="xl:w-1/2 flex justify-between">
      <p className="px-6 py-8 text-gray-400">You&apos;re in a beta mode.</p>
      <div className="flex items-center">
        <Button
          onClick={() => {
            if (!isModeBasic && setIsModeBasic) setIsModeBasic(true);
          }}
          height={isLaptopScreen ? 36 : 40}
          backgroundColor={isModeBasic ? "var(--color-success-500)" : "var(--color-primary-100)"}
          contentClassName="px-4 sm:px-10 py-2 whitespace-nowrap"
        >
          {basicTitle}
        </Button>
        <Button
          onClick={() => {
            if (isModeBasic && setIsModeBasic) setIsModeBasic(false);
          }}
          height={isLaptopScreen ? 36 : 40}
          backgroundColor={!isModeBasic ? "var(--color-success-500)" : "var(--color-primary-100)"}
          contentClassName="px-4 sm:px-10 py-2 whitespace-nowrap"
          name="advanced"
        >
          {advancedTitle}
        </Button>
      </div>
    </div>
  );
}

export default AiModelMode;
