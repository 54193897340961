import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { API_URL, QUERY_KEYS } from "constants/api";
import { BSC_CHAIN_ID } from "constants/global";
import { useAccount, useConnect, useNetwork, useSignMessage, useSwitchNetwork } from "wagmi";

import Axios from "config/axios";

import { useUserContext } from "context/user-context";

import { IGetNonceBody, IVerifySignatureBody } from "interfaces/IAuth";

function useLogin() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isUserLoggedIn } = useUserContext();
  const { loginUser } = useUserContext();
  const { address } = useAccount();
  const { connect, connectors } = useConnect();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const verifySignature = (body: IVerifySignatureBody) => Axios.post(API_URL.VERIFY_SIGNATURE, body);
  const {
    mutate: mutateVerifySig,
    isError: isVerifySigError,
    error: verifySigError,
    isLoading: isVerifySigLoading,
    isSuccess,
  } = useMutation(QUERY_KEYS.VERIFY_SIGNATURE, verifySignature, {
    onSuccess: async ({ data }) => {
      loginUser(data.token, {
        isWhitelisted: data.data.user.isWhitelisted,
        publicAddress: data.data.user.publicAddress,
        credits: data.data.user.credits,
        whitelistingPoints: data.data.user.whitelistingPoints,
      });
      if (searchParams.get("referral_code")) {
        navigate("/referral");
      }
    },
  });

  const getNonce = (body: IGetNonceBody) => Axios.post(API_URL.LOGIN, body);
  const { signMessage, isLoading: isSignMessageLoading } = useSignMessage({
    onSuccess(signature) {
      if (address) {
        mutateVerifySig({
          publicAddress: address,
          signature,
        });
      }
    },
  });

  const {
    mutate,
    isError,
    error,
    isLoading: isGetNonceLoading,
  } = useMutation(QUERY_KEYS.LOGIN, getNonce, {
    onSuccess: d => {
      if (d.data.nonce) {
        signMessage({ message: `I am signing my one-time nonce: ${d.data.nonce}` });
      }
    },
  });

  const { isLoading } = useConnect();
  const handleLogin = async () => {
    if (isUserLoggedIn) return;

    if (chain && chain.id !== BSC_CHAIN_ID && switchNetwork) {
      await switchNetwork(BSC_CHAIN_ID);
      return;
    }
    if (address) {
      mutate({ publicAddress: address, referralCode: searchParams.get("referral_code") });
    } else {
      connect({ connector: connectors[0] });
    }
  };

  const switchNetworkManually = async () => {
    if (chain && chain.id !== BSC_CHAIN_ID && switchNetwork) {
      await switchNetwork(BSC_CHAIN_ID);
    }
  };

  return {
    handleLogin,
    isLoading: isLoading || isSignMessageLoading || isGetNonceLoading || isVerifySigLoading,
    isError: isError || isVerifySigError,
    error: verifySigError || error,
    switchNetworkManually,
    isCorrectChain: chain && chain.id === BSC_CHAIN_ID,
    isSuccess,
  };
}

export default useLogin;
