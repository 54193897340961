// Query keys for react-query - https://react-query.tanstack.com/reference/QueryKey
export const QUERY_KEYS = {
  GET_ERC_20: "get-erc-20",
  LOGIN: "login",
  VERIFY_SIGNATURE: "verify-signature",
  GET_CRYPTO_SIGNALS: "get-crypto-signals",
  SEARCH_BY_PROMPT: "get-using-prompt",
  AUDIT: "audit",
  CODE: "code",
  CHAT: "chat",
  GET_SIGNED_URL: "get-signed-url",
  UPLOAD_FILE: "upload-file",
  GET_SUI_TOKENS: "get-sui-token",
  UPDATE_CREDITS: "update-credits",
  GENERATE_ART: "generate-art",
  AI_TRAINING: "ai-training",
  GET_REFERRAL_CODE: "get-referral-code",
  READ_SECRET_MESSAGE: "read-secret-message",
  SAVE_SECRET_MESSAGE: "save-secret-message",
};

export const API_URL = {
  GET_ERC_20: "/erc-20",
  LOGIN: "/auth/wallet-login",
  VERIFY_SIGNATURE: "/auth/verify",
  GET_CRYPTO_SIGNALS: "/crypto-signals",
  SEARCH_BY_PROMPT: "/search",
  AUDIT: "/api/audit",
  ASK: "/api/ask",
  CODE: "/api/code",
  CHAT: "/api/chat",
  UPLOAD_SIGNED_URL: "/api/get-signed-url",
  UPLOAD_FILE: "/api/upload-file",
  GET_SUI_TOKEN: "/sui-token",
  UPDATE_CREDITS: "/users/update-credits",
  GENERATE_ART: "/api/generate-image",
  AI_TRAINING: "/api/ai-training",
  GET_REFERRAL_CODE: "/users",
  READ_SECRET_MESSAGE: "/api/read-secret-message",
  SAVE_SECRET_MESSAGE: "/api/save-secret-message",
};
