export default {
  promptText: {
    name: "promptText",
    requiredMessage: "Prompt text is required",
  },
  chain: {
    name: "chain",
  },
  q1: {
    name: "q1",
    requiredMessage: "Question 1 is required",
  },
  q2: {
    name: "q2",
    requiredMessage: "Question 2 is required",
  },
  q3: {
    name: "q3",
    requiredMessage: "Question 3 is required",
  },
};
