export function truncateText(text: string, length: number, middle = false) {
  if (text.length > length) {
    if (middle) {
      const middleIndex = Math.ceil(length / 2);
      return `${text.slice(0, middleIndex)}...${text.slice(text.length - middleIndex, text.length)}`;
    }

    return `${text.slice(0, length)}...`;
  }
  return text;
}
