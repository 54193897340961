import { useEffect, useState } from "react";
import { useMutation } from "react-query";

import { Document, Image, PDFDownloadLink, Page, Text, View } from "@react-pdf/renderer";
import { clsx } from "clsx";
import { API_URL, QUERY_KEYS } from "constants/api";
import { demoAuditReport, dummyPromptCode } from "constants/global";
import { Field, Form, Formik } from "formik";

import Axios from "config/axios";

import Button from "components/core-ui/button";
import CopyToClipboard from "components/core-ui/copy-to-clipboard/copy-to-clipboard";
import ErrorMessage from "components/core-ui/error-message/error-message";
import { Input } from "components/core-ui/input";
import SelectChain from "components/select-chain/select-chain";

import useUpdateCredits from "hooks/api/useUpdateCredits";

import CopyToClipboardIcon from "assets/icons/copy.svg";
import LogoWhite from "assets/images/logo-white.png";

import { IAiModelProps } from "../IAiModel";
import AiModelMode from "../ai-model-mode";
import { IAuditBodyRequest } from "../ai-training/IAiTraining";
import DefaultScreen from "../components/default-screen";
import formFields from "./form-fields";
import { formSchema } from "./form-schema";

function SmartContractAuditor({ id }: IAiModelProps) {
  const [isModeBasic, setIsModeBasic] = useState(true);
  const [auditResponse, setAuditResponse] = useState(null);

  const { chain, code } = formFields;

  const getAudit = (body: IAuditBodyRequest) => Axios.post(API_URL.AUDIT, body);
  const { mutate, isLoading, data, isSuccess, reset, isError, error } = useMutation(QUERY_KEYS.AUDIT, getAudit);

  useEffect(() => {
    if (data) {
      setAuditResponse(data?.data?.response);
    }
  }, [data]);

  useUpdateCredits(isSuccess, reset);

  return (
    <>
      <AiModelMode isModeBasic={isModeBasic} setIsModeBasic={setIsModeBasic} advancedTitle="Advanced (PDF)" />
      <div id={id} className="xl:flex gap-2">
        <div className="w-full xl:w-1/2">
          <div className="px-2 py-8">
            <h2 className="text-20px font-medium text-primary">Audit Smart Contracts</h2>
            <p>Security Comes First, perform the audit of any Smart Contract of any network with your GPT Guru.</p>
          </div>
          <div className="rounded-lg bg-primary-100 px-6 py-6 flex flex-col gap-4">
            <p className="text-18px">For developers use only, please write code to generate audit report.</p>
            <Formik
              initialValues={{
                code: dummyPromptCode,
                chain: "sui",
                promptText: "",
              }}
              validationSchema={formSchema}
              onSubmit={values => {
                setAuditResponse(null);
                mutate({ query: `Audit this code please, ${values.code}` });
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <SelectChain disabledOptionKeys={["spl"]} onChange={value => setFieldValue(chain.name, value)} />

                  <Field
                    name={code.name}
                    type="text"
                    error={errors.code && touched.code ? errors.code : null}
                    label="Code"
                    as={Input.TextArea}
                    className="mb-8"
                  />

                  <div className={clsx("flex items-center mt-16", isError ? "justify-between" : "justify-end")}>
                    {isError && <ErrorMessage error={error} />}
                    <Button loading={isLoading} type="submit" width={140} backgroundColor="var(--color-primary)">
                      Go Guru
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="flex-1 lg:w-full xl:w-1/2  bg-tertiary-100 py-12 px-6 rounded-lg">
          {auditResponse && (
            <div className="h-[600px] overflow-auto">
              <p className="text-center text-30px mt-4 font-medium mb-6">Audit report by Guru AI</p>

              <p className="whitespace-pre-wrap">{auditResponse}</p>
            </div>
          )}

          {!auditResponse && (
            <DefaultScreen
              isLoading={isLoading}
              className="mx-auto my-32"
              description="Audit your smart contract with confidence."
            />
          )}

          <div className="flex gap-8 py-4">
            {!isModeBasic && auditResponse && (
              <PDFDownloadLink
                document={
                  <Document>
                    <Page
                      size="A4"
                      style={{
                        padding: 30,
                        fontSize: 12,
                      }}
                    >
                      <View>
                        <View style={{ display: "flex", alignItems: "center", gap: 20 }}>
                          <Image src={LogoWhite} style={{ width: 120 }} />
                          <Text style={{ fontSize: 20, fontWeight: 500, textAlign: "center" }}>
                            Audit Report by GPT Guru
                          </Text>
                        </View>
                        <Text style={{ fontSize: 10, textAlign: "center", marginBottom: 30 }}>
                          (This is an automated audit report generated by GPT Guru AI)
                        </Text>
                        <Text>{auditResponse}</Text>
                      </View>
                    </Page>
                  </Document>
                }
                fileName="audit-report.pdf"
              >
                {({ loading }) => <Button loading={loading}>Download</Button>}
              </PDFDownloadLink>
            )}
            {auditResponse && (
              <CopyToClipboard
                text={demoAuditReport}
                prefix={<img className="w-7" src={CopyToClipboardIcon} alt="copy to clipboard" />}
              >
                <span className="font-medium">Copy</span>
              </CopyToClipboard>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SmartContractAuditor;
