import { useState } from "react";
import { useMutation } from "react-query";

import { clsx } from "clsx";
import { API_URL, QUERY_KEYS } from "constants/api";
import { dummyPromptCode, questions } from "constants/global";
import { Field, Form, Formik } from "formik";

import Axios from "config/axios";

import Button from "components/core-ui/button";
import { Input } from "components/core-ui/input";
import Video from "components/core-ui/video";
import SelectChain from "components/select-chain/select-chain";

import AIModelLoader from "assets/media/ai-model-loader.mp4";

import { IAiModelProps } from "../IAiModel";
import AiModelMode from "../ai-model-mode";
import DefaultScreen from "../components/default-screen";
import { ChainsType, IAiTrainingBody } from "./IAiTraining";
import formFields from "./form-fields";
import { formSchema } from "./form-schema";

function AiTraining({ id }: IAiModelProps) {
  const [currentChain, setCurrentChain] = useState<ChainsType>("erc-20");
  const [isModeBasic, setIsModeBasic] = useState(true);
  const [loader, setLoader] = useState(false);

  const { chain, promptText, q1, q2, q3 } = formFields;

  const getAiTraining = (body: IAiTrainingBody) => Axios.post(API_URL.AI_TRAINING, body);
  const { mutate, isLoading, isSuccess, reset } = useMutation(QUERY_KEYS.AI_TRAINING, getAiTraining);

  return (
    <>
      <AiModelMode isModeBasic={isModeBasic} setIsModeBasic={setIsModeBasic} />

      <div id={id} className="xl:flex gap-2">
        <div className="w-full xl:w-1/2">
          <div className="px-6 py-8">
            <h2 className="text-20px font-medium text-primary">Train Guru AI</h2>
            <p>Everyday, 3 questions will be generated randomly for Guru community to train Guru AI.</p>
          </div>

          <div className="rounded-lg bg-primary-100 px-6 py-6 flex flex-col gap-8">
            <Formik
              initialValues={{
                promptText: dummyPromptCode,
                chain: "erc-20",
                [q1.name]: "",
                [q2.name]: "",
                [q3.name]: "",
              }}
              validationSchema={formSchema[isModeBasic ? "basic" : "advanced"]}
              onSubmit={values => {
                reset();
                setLoader(true);
                setTimeout(() => {
                  mutate({
                    query: isModeBasic ? `q1.${values.q1} q2.${values.q2} q3.${values.q3}` : values.promptText,
                  });
                  setLoader(false);
                }, 3000);
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <SelectChain
                    onChange={value => {
                      setFieldValue(chain.name, value);
                      setCurrentChain(value as ChainsType);
                    }}
                  />

                  {isModeBasic ? (
                    <>
                      <Field
                        label={questions[currentChain].q1}
                        name={q1.name}
                        type="text"
                        error={errors.q1 && touched.q1 ? errors.q1 : null}
                        as={Input}
                        className="mb-6"
                        placeholder="Enter your answer"
                      />
                      <Field
                        label={questions[currentChain].q2}
                        name={q2.name}
                        type="text"
                        error={errors.q2 && touched.q2 ? errors.q2 : null}
                        as={Input}
                        className="mb-6"
                        placeholder="Enter your answer"
                      />
                      <Field
                        label={questions[currentChain].q3}
                        name={q3.name}
                        type="text"
                        error={errors.q3 && touched.q3 ? errors.q3 : null}
                        as={Input}
                        className="mb-6"
                        placeholder="Enter your answer"
                      />
                    </>
                  ) : (
                    <Field
                      name={promptText.name}
                      type="text"
                      error={errors.promptText && touched.promptText ? errors.promptText : null}
                      label="Code"
                      as={Input.TextArea}
                      placeholder="Enter the dataset code of smart contract against the selected chain."
                    />
                  )}

                  <div className={clsx("flex items-center mt-16 justify-end")}>
                    <Button
                      loading={isLoading || loader}
                      type="submit"
                      width={140}
                      backgroundColor="var(--color-primary)"
                    >
                      Go Guru
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="flex-1 lg:w-full xl:w-1/2">
          <div className="border border-gray-800 rounded-lg p-6 min-h-[680px] flex">
            {!isLoading && !isSuccess && !loader && (
              <DefaultScreen className="mx-auto my-24" description="Train an AI and get rewarded, on any network." />
            )}

            <div>
              {(isLoading || isSuccess || loader) && (
                <div>
                  <h1 className="uppercase  mb-4 text-32px font-bold font-serif text-center">
                    {isSuccess ? (
                      <>
                        <span className="text-secondary">Dataset</span> has been submitted
                      </>
                    ) : (
                      <>
                        Submitting <span className="text-secondary">Dataset</span>
                      </>
                    )}
                  </h1>
                  <Video videSource={AIModelLoader} autoplay loop />
                </div>
              )}

              {isSuccess && !isLoading && !loader && (
                <div className="mt-8">
                  <p className="text-center">
                    It might take 1-2 weeks to check if submitted data is relevant or not.
                    <br />
                    <p className="text-20px font-medium my-2">
                      Upon dataset approval for AI Training, you&apos;ll be able to claim your{" "}
                      <span className="text-primary">$GPTG</span> rewards accordingly.
                    </p>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AiTraining;
