import { Abi } from "abitype";

import { ContractType, INetworkID, NetworkType } from "./IWeb3";

import GptGuruClaimToken from "./contracts/ClaimToken.json";
import GptGuruToken from "./contracts/ERC-20.json";
import GptGuruSaleToken from "./contracts/GPTGuruSale.json";
import GptGuruQuickNFT from "./contracts/GptGuruQuickNFT.json";
import SecretMessaging from "./contracts/Messaging.json";
import GptGuruStaking from "./contracts/Staking.json";

export const getContractABI = (contractType: ContractType) => {
  let abi;
  switch (contractType) {
    case "GptGuruQuickNFT":
      abi = GptGuruQuickNFT.abi;
      break;
    case "GptGuruClaimToken":
      abi = GptGuruClaimToken.abi;
      break;
    case "GptGuruSaleToken":
      abi = GptGuruSaleToken.abi;
      break;
    case "TokenAbi":
      abi = GptGuruToken.abi;
      break;
    case "GptGuruStaking":
      abi = GptGuruStaking.abi;
      break;
    case "GptGuruSecretMessaging":
      abi = SecretMessaging.abi;
      break;
    default:
      abi = null;
  }
  return abi as unknown as Abi;
};

const getAddress = (networks: INetworkID, id: NetworkType) => (networks ? networks[id]?.address : "");

export const getContractAddress = (contractType: ContractType, id: NetworkType) => {
  let address;
  switch (contractType) {
    case "GptGuruQuickNFT":
      address = getAddress(GptGuruQuickNFT.networks, id);
      break;

    case "GptGuruClaimToken":
      address = getAddress(GptGuruClaimToken.networks, id);
      break;
    case "TokenAbi":
      address = getAddress(GptGuruToken.networks, id);
      break;
    case "GptGuruSaleToken":
      address = getAddress(GptGuruSaleToken.networks, id);
      break;
    case "GptGuruStaking":
      address = getAddress(GptGuruStaking.networks, id);
      break;
    case "GptGuruSecretMessaging":
      address = getAddress(SecretMessaging.networks, id);
      break;
    default:
      address = undefined;
  }
  return address;
};
