import * as ethers from "ethers";
import moment from "moment";
import { TypeBigNumber, TypeWagmiError } from "web3/IWeb3";

export const addressZero = () => "0x0000000000000000000000000000000000000000";

export const ether = (n: string) => {
  return ethers.utils.parseEther(n);
};

export const toBN = (val: string) => {
  return ethers.BigNumber.from(val.toString());
};

export const toRealNumber = (val: string) => {
  return Number(val.toString());
};

export const toNumber = (bigNumber: ethers.BigNumber, decimalPlaces = 2) => {
  if (!bigNumber) return 0;
  const powerValue = 18 - decimalPlaces;
  const base = ethers.BigNumber.from(10);
  const power = ethers.BigNumber.from(powerValue);
  const divisor = base.pow(power);
  return bigNumber.div(divisor).toNumber() / 10 ** decimalPlaces;
};

export const parseError = ({ message, code }: { message: string; code: number }) => {
  if (message.includes("JSON-RPC error")) {
    const errObj = JSON.parse(message.split("\n").slice(1).join("\n"));
    const parsedMessage = errObj.message ? errObj.message.replace("execution reverted: ", "") : JSON.stringify(errObj);
    return parsedMessage.includes("insufficient funds for transfer") ? "Insufficient Balance!" : parsedMessage;
  }
  if (code === 4001) {
    return "Transaction Rejected!";
  }
  return `Transaction Failed: ${message}!`;
};

export const parseBigNumber = (number: ethers.BigNumber) => {
  return Math.round(parseFloat(ethers.utils.formatEther(number)) * 10 ** 18);
};

export const getCurrentTimeSeconds = () => moment.utc(new Date()).unix();

export const getWagmiErrorReason = (error: Error | null) => {
  const errorReason = (error as TypeWagmiError)?.reason;
  return errorReason || null;
};

export const parseWeiToEtherWithDecimals = (wei: TypeBigNumber, decimals = 2) => {
  return parseFloat(ethers.utils.formatEther(wei)).toFixed(decimals);
};

export const parseBigNumberTimeToDays = (bigNumber: TypeBigNumber) => {
  return Math.round(parseBigNumber(bigNumber) / 86400);
};

export const parseEtherToWei = (eth: number) => {
  return ethers.utils.parseEther(eth.toString());
};
