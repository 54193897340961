export default {
  chain: {
    name: "chain",
    requiredMessage: "Chain is required",
  },
  promptText: {
    name: "promptText",
    requiredMessage: "Prompt text is required",
  },
  code: {
    name: "code",
    requiredMessage: "Code is required",
  },
};
