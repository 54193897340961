export default {
  contractName: {
    name: "contractName",
    requiredMessage: "Name is required",
  },
  contractSymbol: {
    name: "contractSymbol",
    requiredMessage: "Symbol is required",
  },
  file: {
    name: "file",
    requiredMessage: "File is required",
  },
  promptText: {
    name: "promptText",
  },
};
