import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";

import { clsx } from "clsx";
import { API_URL, QUERY_KEYS } from "constants/api";
import { SECRET_MESSAGE_LENGTH } from "constants/global";
import { Field, Form, Formik } from "formik";
import useReadSecretMessage from "web3/hooks/useReadSecretMessage";
import useWriteSecretMessage from "web3/hooks/useWriteSecretMessage";
import * as yup from "yup";

import Axios from "config/axios";

import Button from "components/core-ui/button";
import CopyToClipboard from "components/core-ui/copy-to-clipboard/copy-to-clipboard";
import { Input } from "components/core-ui/input";
import DotLoader from "components/core-ui/loader/dot-loader";

import useUpdateCredits from "hooks/api/useUpdateCredits";

import { IAiModelProps } from "../IAiModel";
import DefaultScreen from "../components/default-screen";
import { IReadMessageBody } from "./ISecretMessaging";
// import DefaultScreen from "../components/default-screen";
import formFields from "./form-fields";
import MessagingTabs from "./messaging-tabs";

function SecretMessaging({ id }: IAiModelProps) {
  const { receiver, message } = formFields;
  const [decryptedMessages, setDecryptedMessages] = useState({
    received: [],
    sent: [],
  });

  const [total, setTotal] = useState({
    received: 5,
    sent: 5,
  });
  const [isActiveTab, setIsActiveTab] = useState(true);

  const {
    isLoading,
    values,
    isRefetching,
    fetchStatusReceivedMessageCount,
    fetchStatusSentMessageCount,
    fetchStatusMessageById,
  } = useReadSecretMessage(total);

  const readSecretMessage = (formData: IReadMessageBody) => Axios.post(API_URL.READ_SECRET_MESSAGE, formData);

  const {
    mutate: mutateReadReceivedSecretMessage,
    // isError: isreadSecretMessageError,
    // error: readSecretMessageError,
    isSuccess: isReadReceivedSecretMessageSuccess,
    isLoading: isReadReceivedSecretMessageLoading,
    data: readReceivedSecretMessageData,
    reset: resetReadReceivedSecretMessage,
  } = useMutation(QUERY_KEYS.READ_SECRET_MESSAGE, readSecretMessage);

  useEffect(() => {
    if (isReadReceivedSecretMessageSuccess && readReceivedSecretMessageData) {
      setDecryptedMessages({
        ...decryptedMessages,
        received: readReceivedSecretMessageData?.data?.data,
      });
      resetReadReceivedSecretMessage();
    }
  }, [
    decryptedMessages,
    isReadReceivedSecretMessageSuccess,
    readReceivedSecretMessageData,
    resetReadReceivedSecretMessage,
  ]);

  const {
    mutate: mutateReadSentSecretMessage,
    // isError: isreadSecretMessageError,
    // error: readSecretMessageError,
    isSuccess: isReadSentSecretMessageSuccess,
    isLoading: isReadSentSecretMessageLoading,
    data: readSentSecretMessageData,
    reset: resetReadSentSecretMessage,
  } = useMutation(QUERY_KEYS.READ_SECRET_MESSAGE, readSecretMessage);

  useEffect(() => {
    if (isReadSentSecretMessageSuccess && readSentSecretMessageData) {
      setDecryptedMessages({
        ...decryptedMessages,
        sent: readSentSecretMessageData?.data?.data,
      });
      resetReadSentSecretMessage();
    }
  }, [decryptedMessages, isReadSentSecretMessageSuccess, readSentSecretMessageData, resetReadSentSecretMessage]);

  useEffect(() => {
    if (isReadReceivedSecretMessageSuccess && readReceivedSecretMessageData) {
      setDecryptedMessages({
        ...decryptedMessages,
        received: readReceivedSecretMessageData?.data?.data,
      });
    }
  }, [decryptedMessages, isReadReceivedSecretMessageSuccess, readReceivedSecretMessageData]);

  useEffect(() => {
    if (
      typeof values.receivedMessageCount === "number" &&
      (values.receivedMessageCount === values.receivedMessages.length ||
        values.receivedMessages.length === total.received) &&
      fetchStatusReceivedMessageCount === "idle" &&
      values.receivedMessages.every(m => !!m.text)
    ) {
      const messages = values.receivedMessages.map(m => m.text);

      mutateReadReceivedSecretMessage({ messages });
    }

    if (
      typeof values.sentMessageCount === "number" &&
      (values.sentMessageCount === values.sentMessages.length || values.sentMessages.length === total.sent) &&
      fetchStatusSentMessageCount === "idle" &&
      values.sentMessages.every(m => !!m.text)
    ) {
      const sentMessages = values.sentMessages.map(m => m.text);
      mutateReadSentSecretMessage({ messages: sentMessages });
    }
  }, [
    fetchStatusReceivedMessageCount,
    fetchStatusSentMessageCount,
    mutateReadReceivedSecretMessage,
    mutateReadSentSecretMessage,
    total.received,
    total.sent,
    values.receivedMessageCount,
    values.receivedMessages,
    values.receivedMessages.length,
    values.sentMessageCount,
    values.sentMessages,
  ]);

  const data = useMemo(() => {
    if (isActiveTab) {
      return values?.receivedMessages && Array.isArray(values.receivedMessages) && values.receivedMessages.length > 0
        ? values.receivedMessages
        : [];
    }
    return values?.sentMessages && Array.isArray(values.sentMessages) && values.sentMessages.length > 0
      ? values.sentMessages
      : [];
  }, [isActiveTab, values.receivedMessages, values.sentMessages]);

  const handleLoadMore = () => {
    if (isActiveTab) {
      setTotal(prev => ({
        ...prev,
        received: prev.received + 5,
      }));
      return;
    }
    setTotal(prev => ({
      ...prev,
      sent: prev.sent + 5,
    }));
  };

  const {
    writeSendMessageWrite,
    isLoadingWriteSendMessage,
    formValues,
    setFormValues,
    isWriteSendMessageTxSuccess,
    resetWriteSendMessage,
  } = useWriteSecretMessage();

  // Formik for validation schema
  const formSchema = yup.object().shape({
    [receiver.name]: yup
      .string()
      .required(receiver.requiredMessage)
      .matches(/^0x[a-fA-F0-9]{40}$/, receiver.invalidMessage),
    [message.name]: yup
      .string()
      .required(message.requiredMessage)
      .max(SECRET_MESSAGE_LENGTH, `Must be less than ${SECRET_MESSAGE_LENGTH} characters`),
  });

  const saveSecretMessage = () =>
    Axios.post(API_URL.SAVE_SECRET_MESSAGE, { message: formValues.message, receiver: formValues.receiver });

  const {
    mutate: mutateSaveSecretMessage,
    isError: isSaveSecretMessageError,
    isLoading: isSaveSecretMessageLoading,
    isSuccess: isSaveSecretMessageSuccess,
    reset: resetSaveSecretMessage,
    data: saveSecretMessageData,
    error: saveSecretMessageError,
  } = useMutation(QUERY_KEYS.SAVE_SECRET_MESSAGE, saveSecretMessage);

  useEffect(() => {
    if (isSaveSecretMessageError) {
      resetSaveSecretMessage();
    }

    if (isSaveSecretMessageSuccess) {
      setFormValues({
        ...formValues,
        encryptedResult: saveSecretMessageData?.data?.data,
      });

      resetSaveSecretMessage();

      if (writeSendMessageWrite) {
        writeSendMessageWrite();
      }
    }
  }, [
    formValues,
    isSaveSecretMessageError,
    isSaveSecretMessageSuccess,
    resetSaveSecretMessage,
    saveSecretMessageData,
    setFormValues,
    writeSendMessageWrite,
    saveSecretMessageError,
  ]);

  useUpdateCredits(isWriteSendMessageTxSuccess, resetWriteSendMessage);

  return (
    <div id={id} className="2xl:flex gap-2">
      <div className="w-full 2xl:w-1/2">
        <div className="px-2 py-8">
          <h2 className="text-20px font-medium text-primary">Secret Message</h2>
          <p>
            Start sending secret and secured messages across blockchain, anonymously to anyone anytime to bring fun and
            curiosity with GPT Guru.
          </p>
        </div>

        <div className="rounded-lg bg-primary-100 px-6 py-6 flex flex-col gap-4">
          <Formik
            initialValues={{
              receiver: "",
              message: "",
            }}
            validationSchema={formSchema}
            onSubmit={() => {
              mutateSaveSecretMessage();
            }}
          >
            {({ errors, touched, setFieldValue }) => {
              if (isWriteSendMessageTxSuccess) {
                setFormValues({
                  ...formValues,
                  message: "",
                  encryptedResult: "",
                });
              }
              return (
                <Form>
                  <Field
                    name={receiver.name}
                    type="address"
                    error={errors.receiver && touched.receiver ? errors.receiver : null}
                    label="Receiver's Wallet Address (ETH Address Only)"
                    as={Input}
                    value={formValues.receiver}
                    className="mb-6"
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setFormValues(() => ({
                        ...formValues,
                        receiver: e.target.value,
                      }));
                      setFieldValue("receiver", e.target.value);
                    }}
                    defaultValue={formValues.receiver}
                  />

                  <Field
                    name={message.name}
                    type="text"
                    value={formValues.message}
                    error={errors.message && touched.message ? errors.message : null}
                    label="Message"
                    as={Input.TextArea}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setFormValues(() => ({
                        ...formValues,
                        message: e.target.value,
                        encryptedResult: "",
                      }));
                      setFieldValue("message", e.target.value);
                    }}
                    maxLength={SECRET_MESSAGE_LENGTH}
                    defaultValue={formValues.message}
                  />

                  <div className={clsx("flex items-center mt-8")}>
                    <Button
                      loading={isLoading || isLoadingWriteSendMessage || isRefetching || isSaveSecretMessageLoading}
                      type="submit"
                      backgroundColor="var(--color-primary)"
                      disabled={!formValues.receiver || !formValues.message || !!errors.receiver || !!errors.message}
                    >
                      {formValues.encryptedResult ? "Confirm Send" : "Send Secret Message"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="flex-1 lg:w-full 2xl:w-1/2 rounded-lg bg-tertiary-100">
        <div className="py-12 px-4 2xl:px-6 rounded-lg">
          <MessagingTabs isActiveTab={isActiveTab} setIsActiveTab={setIsActiveTab} />

          {isLoading ||
          isRefetching ||
          fetchStatusReceivedMessageCount === "fetching" ||
          fetchStatusMessageById === "fetching" ||
          (fetchStatusSentMessageCount === "fetching" && readReceivedSecretMessageData && readSentSecretMessageData) ? (
            <div className="flex justify-center my-20">
              <DotLoader />
            </div>
          ) : (
            <div className="border border-primary-200 rounded-lg p-4 4xl:p-6">
              {data.length > 0 ? (
                <div>
                  {data.map(({ id: receivedMessageId, from, to }, i) => (
                    <div key={receivedMessageId} className="bg-primary-200 rounded-lg px-4 4xl:px-10 py-4 mb-4">
                      <div className="md:flex gap-6">
                        <p className="mb-2 text-14px font-light w-[120px]">
                          {isActiveTab ? "Sender" : "Receiver"}&apos;s Address:
                        </p>
                        <div className="md:w-[calc(100%-120px)]">
                          <CopyToClipboard
                            text={isActiveTab ? from : to}
                            className="flex"
                            contentClassName=""
                            backgroundColor="transparent"
                          >
                            <p className="mb-2 font-medium flex-1 break-all">{isActiveTab ? from : to}</p>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div className="md:flex gap-6">
                        <p className="text-14px font-light w-[120px]">Message:</p>
                        {isReadReceivedSecretMessageLoading || isReadSentSecretMessageLoading ? (
                          <div className="scale-50">
                            <DotLoader />
                          </div>
                        ) : (
                          <p className="font-light flex-1 break-all">
                            {isActiveTab ? decryptedMessages?.received?.[i] : decryptedMessages?.sent?.[i]}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}

                  {isActiveTab && values.receivedMessageCount && total.received < values.receivedMessageCount - 1 && (
                    <Button
                      onClick={handleLoadMore}
                      variant="secondary"
                      className="mx-auto"
                      contentClassName="px-6 py-2"
                    >
                      Load more
                    </Button>
                  )}
                  {!isActiveTab && values.sentMessageCount && total.sent < values.sentMessageCount - 1 && (
                    <Button
                      onClick={handleLoadMore}
                      variant="secondary"
                      className="mx-auto"
                      contentClassName="px-6 py-2"
                    >
                      Load more
                    </Button>
                  )}
                </div>
              ) : (
                <DefaultScreen
                  isLoading={isLoading || isRefetching}
                  className="mx-auto my-32"
                  description="Send Secret Message Anonymously to Anyone."
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SecretMessaging;
