import { useEffect, useMemo, useState } from "react";

import { AxiosError } from "axios";
import { clsx } from "clsx";

import WarningIcon from "assets/icons/warning-icon-danger.svg";

import { IErrorMessageProps } from "./IErrorMessageProps";

function ErrorMessage({ message, error }: IErrorMessageProps) {
  const [show, setShow] = useState(true);

  const errorMessage = useMemo(() => {
    if (error && error instanceof AxiosError) {
      return Array.isArray(error.response?.data?.message[0])
        ? error.response?.data?.message[0]
        : error.response?.data?.message;
    }
    return "Something went wrong, please try again.";
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 8000);
  }, []);

  return (
    <p className={clsx(show ? "visible" : "invisible")}>
      <img className="inline-block me-2" src={WarningIcon} alt="Warning" />{" "}
      <span className="text-red-600 capitalize">{message || errorMessage}</span>
    </p>
  );
}

export default ErrorMessage;
