export function getSuiCodeType(isMintable = false, isBurnable = false) {
  if (isMintable && isBurnable) {
    return "withMintableandBurnable";
  }
  if (isMintable) {
    return "withMintable";
  }
  if (isBurnable) {
    return "withBurnable";
  }
  return "simple";
}
