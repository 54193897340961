import * as yup from "yup";

import formFields from "./form-fields";

const { contractName, contractSymbol, file } = formFields;

export const formSchema = yup.object().shape({
  [contractName.name]: yup.string().required(contractName.requiredMessage),
  [contractSymbol.name]: yup.string().required(contractSymbol.requiredMessage),
  [file.name]: yup.array().of(
    yup
      .mixed()
      .test("fileSize", "File size greater than 100mb not allowed / excluded if multiple files selected.", value => {
        const targetValue = value as File;

        return targetValue && targetValue?.size <= 100000000;
      })
  ),
});
