import { useEffect, useState } from "react";
import { useMutation } from "react-query";

import { clsx } from "clsx";
import { API_URL, QUERY_KEYS } from "constants/api";
import { smartContractPromptOptions } from "constants/global";
import { Field, Form, Formik } from "formik";
import Typewriter from "typewriter-effect";

import Axios from "config/axios";

import Button from "components/core-ui/button";
import CopyToClipboard from "components/core-ui/copy-to-clipboard/copy-to-clipboard";
import ErrorMessage from "components/core-ui/error-message/error-message";
import { Input } from "components/core-ui/input";
import Switch from "components/core-ui/switch";
import SelectChain from "components/select-chain/select-chain";

import useUpdateCredits from "hooks/api/useUpdateCredits";

import CopyToClipboardIcon from "assets/icons/copy.svg";

import { GetSmartContractPrompt } from "utils/get-smart-contract-prompt";

import { IAiModelProps } from "../IAiModel";
import AiModelMode from "../ai-model-mode";
import DefaultScreen from "../components/default-screen";
import { IGetErc20Body } from "./ISmartContracts";
import formFields from "./form-fields";
import { formSchema } from "./form-schema";

function SmartContracts({ id }: IAiModelProps) {
  const [isModeBasic, setIsModeBasic] = useState(true);
  const [concatenatedResponse, setConcatenatedResponse] = useState("");
  const [currentChain, setCurrentChain] = useState("erc-20");
  const [prompt, setPrompt] = useState("");

  const { chain, tokenName, tokenSupply, tokenSymbol, isMintable, isBurnable, promptText } = formFields;
  const PROMPT_TEXT_DEFAULT_VALUE = `Create a smart contract for an ${smartContractPromptOptions[currentChain].token} token. The token name is Banana Hunters, the token symbol is $BNHN, and the token supply is 1000000000. The token is mintable and burnable. Generate code in ${smartContractPromptOptions[currentChain].language}`;

  const getAsk = (body: IGetErc20Body) =>
    Axios.post(API_URL.CODE, {
      query: isModeBasic ? body.promptText : prompt || PROMPT_TEXT_DEFAULT_VALUE,
    });

  const { mutate, isLoading, data, isError, isSuccess, reset, error } = useMutation(QUERY_KEYS.CODE, getAsk);

  useEffect(() => {
    if (data?.data?.response) {
      setConcatenatedResponse(data.data.response);
    }
  }, [data, isSuccess]);

  useUpdateCredits(isSuccess, reset);

  return (
    <>
      <AiModelMode isModeBasic={isModeBasic} setIsModeBasic={setIsModeBasic} />
      <div id={id} className="xl:flex gap-2">
        <div className="w-full xl:w-1/2">
          <div className="px-6 py-8">
            <h2 className="text-20px font-medium text-primary">Generate smart contract</h2>
            <p>
              With Guru AI, you can generate a smart contract across any network way fast and easily like never before.
            </p>
          </div>

          <div className="rounded-lg bg-primary-100 px-6 py-6 flex flex-col gap-8">
            <Formik
              initialValues={{
                tokenName: "",
                tokenSymbol: "",
                tokenSupply: "",
                chain: "erc-20",
                isMintable: true,
                isBurnable: true,
              }}
              validationSchema={formSchema[isModeBasic ? "basic" : "advanced"]}
              onSubmit={values => {
                setConcatenatedResponse("");
                mutate({
                  promptText: GetSmartContractPrompt({
                    ...values,
                    tokenType: smartContractPromptOptions[currentChain].token,
                    language: smartContractPromptOptions[currentChain].language,
                  }),
                });
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <SelectChain
                    onChange={value => {
                      setFieldValue(chain.name, value);
                      setCurrentChain(value);
                    }}
                  />
                  {isModeBasic ? (
                    <>
                      <Field
                        name={tokenName.name}
                        type="text"
                        error={errors.tokenName && touched.tokenName ? errors.tokenName : null}
                        label="Token name"
                        as={Input}
                        className="mb-8"
                        placeholder="Banana Hunters"
                      />
                      <Field
                        name={tokenSymbol.name}
                        type="text"
                        error={errors.tokenSymbol && touched.tokenSymbol ? errors.tokenSymbol : null}
                        label="Token symbol"
                        as={Input}
                        className="mb-8"
                        placeholder="$BNHN"
                      />
                      <Field
                        name={tokenSupply.name}
                        type="number"
                        error={errors.tokenSupply && touched.tokenSupply ? errors.tokenSupply : null}
                        label="Token supply"
                        as={Input}
                        className="mb-8"
                        placeholder="1000000000"
                      />

                      <div className="flex gap-16 mb-12">
                        <Switch label="Mintable" checked onChange={value => setFieldValue(isMintable.name, value)} />
                        <Switch label="Burnable" checked onChange={value => setFieldValue(isBurnable.name, value)} />
                      </div>
                    </>
                  ) : (
                    <Input.TextArea
                      label="Prompt Text"
                      name={promptText.name}
                      value={prompt}
                      defaultValue={PROMPT_TEXT_DEFAULT_VALUE}
                      onChange={e => setPrompt(e.target.value)}
                    />
                  )}

                  <div className={clsx("flex items-center", isError ? "justify-between" : "justify-end")}>
                    {isError && <ErrorMessage error={error} />}
                    <Button loading={isLoading} type="submit" width={140} backgroundColor="var(--color-primary)">
                      Go Guru
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="flex-1 lg:w-full xl:w-1/2 bg-tertiary-100 py-8 px-6 rounded-lg">
          {concatenatedResponse ? (
            <>
              <div className="bg-primary- border border-primary-100 rounded-md h-[650px] overflow-auto p-4 whitespace-pre-wrap mb-8">
                <Typewriter
                  options={{
                    delay: 10,
                  }}
                  onInit={typewriter => {
                    typewriter.typeString(concatenatedResponse).start();
                  }}
                />
              </div>
              {concatenatedResponse && (
                <div className="flex gap-2">
                  <CopyToClipboard
                    text={concatenatedResponse}
                    prefix={<img className="w-7" src={CopyToClipboardIcon} alt="copy to clipboard" />}
                  >
                    <span className="font-medium">Copy</span>
                  </CopyToClipboard>
                  <Button onClick={() => setConcatenatedResponse("")} variant="text" className="text-info">
                    Clear
                  </Button>
                </div>
              )}
            </>
          ) : (
            <DefaultScreen
              isLoading={isLoading}
              className="my-32"
              description="Generate a smart contact on any network. "
            />
          )}
        </div>
      </div>
    </>
  );
}

export default SmartContracts;
