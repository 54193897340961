import { Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Listbox, Transition } from "@headlessui/react";
import { BSC_CHAIN_ID } from "constants/global";
import { useAccount, useNetwork } from "wagmi";

import Button from "components/core-ui/button";

import { useUserContext } from "context/user-context";

import useLogin from "hooks/api/useLogin";

import ArrowDown from "assets/icons/arrow-down.svg";
import BotIconWhite from "assets/icons/bot-icon-white.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout-icon.svg";

import { truncateText } from "utils/truncate-text";

function Profile() {
  const { isUserLoggedIn, logoutUser } = useUserContext();
  const navigate = useNavigate();

  const { handleLogin, isLoading, isSuccess } = useLogin();
  const { chain } = useNetwork();

  const { address } = useAccount();
  useEffect(() => {
    if (isSuccess) {
      navigate("/ai-model/smart-contracts");
    }
  }, [isSuccess, navigate]);

  return isUserLoggedIn ? (
    <Listbox>
      <div className="relative mt-1">
        <Listbox.Button contentClassName="py-2 px-8" as={Button} variant={address ? "primary-outline" : "primary"}>
          {address ? (
            <div>
              <div>
                <p className="text-18px hidden lg:block">Experience GURU AI</p>
                <div className="flex items-center gap-2">
                  <p className="text-12px lg:ms-6">{truncateText(address, 14, true)}</p>
                  <img className="w-[10px] hidden lg:inline-block" src={ArrowDown} alt="" />
                </div>
              </div>
            </div>
          ) : (
            "Connect Wallet"
          )}
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="hidden lg:block w-[275px] absolute right-0 mt-1 z-10 max-h-60 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm text-black">
            <div className="px-4 py-4 flex items-center gap-4">
              <div className="w-[40px] h-[40px] bg-white rounded-full flex items-center justify-center">
                <div className="w-[34px] h-[34px] bg-gradient-to-br from-primary to-secondary rounded-full flex items-center justify-center">
                  <img className="w-[26px] mb-1" src={BotIconWhite} alt="" />
                </div>
              </div>
              <p className="font-semibold text-20px">Guru Account</p>
            </div>
            <hr />
            <Link to="/try">
              <p className="my-4 px-6 flex gap-4 hover:text-primary cursor-pointer font-semibold text-14px">Guru App</p>
            </Link>
            <hr />
            <Button variant="text" contentClassName="my-4 px-6" onClick={logoutUser}>
              <p className="flex items-center gap-4  text-gray-400 hover:text-primary cursor-pointer font-medium hover:font-semibold path:hover:fill-primary ">
                <LogoutIcon /> Logout
              </p>
            </Button>
          </div>
        </Transition>
      </div>
    </Listbox>
  ) : (
    <Button loading={isLoading} onClick={handleLogin}>
      {chain && chain.id !== BSC_CHAIN_ID ? "Switch Network" : address ? "Experience Guru AI" : "Connect Wallet"}
    </Button>
  );
}

export default Profile;
