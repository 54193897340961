/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from "react";

import { clsx } from "clsx";

import AnchorLink from "components/core-ui/anchor-link";

import { IIconItem } from "./IISocialIconsProps";

function RenderSocialWithChildren({
  id,
  size,
  bordered,
  iconWidth,
  iconHeight,
  icon: Icon,
  child,
  isHorizontal,
}: IIconItem) {
  const [showChild, setShowChild] = useState(false);

  return (
    <div className="relative" key={id} onMouseEnter={() => setShowChild(true)} onMouseLeave={() => setShowChild(false)}>
      <button
        style={{ width: size, height: size }}
        className={clsx(
          "flex items-center justify-center",
          bordered &&
            "border-primary border rounded-md hover:bg-gradient-to-br hover:from-tertiary hover:to-secondary path:hover:fill-white path:transition-all path:ease-out path:duration-150"
        )}
        type="button"
      >
        <Icon width={iconWidth} height={iconHeight} />
      </button>
      {showChild && (
        <div
          className={clsx(
            "absolute z-10 p-2 w-max",
            isHorizontal ? "bottom-0 right-1/2 translate-x-1/2" : "top-0 right-0"
          )}
          style={{ marginRight: isHorizontal ? "auto" : size, marginBottom: isHorizontal ? size : "auto" }}
        >
          <div className="flex flex-col items-center justify-center  bg-black bg-opacity-90 text-20px animate-fade-in rounded-md rounded-b-none">
            {child &&
              child.map(({ key, url, title }) => (
                <AnchorLink
                  key={key}
                  href={url}
                  className="w-full text-center px-8 py-6 border-b border-gray-400 last:border-0 hover:bg-gradient-to-br hover:from-tertiary hover:to-secondary transition-all ease-in duration-200 rounded-md rounded-b-none"
                >
                  <p>{title}</p>
                </AnchorLink>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default RenderSocialWithChildren;
