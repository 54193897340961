import { Fragment, useState } from "react";

import { Listbox, Transition } from "@headlessui/react";
import { clsx } from "clsx";

import ArrowDown from "assets/icons/arrow-down.svg";

import { ISelectProps } from "./ISelectProps";

function Select({ label, options, defaultValue = null, onChange, className = "", size = "lg" }: ISelectProps) {
  const [current, setCurrent] = useState(options.find(({ value }) => value === defaultValue));

  const classNames = {
    sm: "px-4 h-[36px]",
    md: "px-6 h-[54px] border border-primary",
    lg: "px-8 h-[54px] border border-primary",
  };

  return (
    <div className={clsx(className)}>
      <Listbox
        value={current}
        onChange={e => {
          setCurrent(e);
          if (onChange) onChange(e);
        }}
      >
        <div className="relative mt-1">
          <p className={clsx(label && "mb-2")}>{label}</p>
          <Listbox.Button
            className={clsx(
              "flex justify-between items-center gap-6 relative w-full cursor-default rounded-lg text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm",
              classNames[size]
            )}
          >
            <span className={clsx("block truncate", !current?.label && "text-gray-400")}>{current?.label}</span>
            <img src={ArrowDown} alt="" />
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute mt-1 z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map(({ label: title, value, disabled = false }) => (
                <Listbox.Option
                  disabled={disabled}
                  key={value}
                  className={({ active }) =>
                    `relative cursor-default select-none py-3 pl-10 pr-4 ${
                      active ? "bg-primary text-white" : "text-gray-900"
                    } ${disabled && "opacity-50"}`
                  }
                  value={{ label: title, value }}
                >
                  {({ selected }) => (
                    <div className="flex items-center">
                      <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{title}</span>{" "}
                      {disabled && <span className="text-10px ms-2">(Coming Soon)</span>}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default Select;
