import { Suspense } from "react";

import BackToTop from "components/core-ui/bact-to-top/back-to-top";
import Video from "components/core-ui/video";

import Loader from "assets/media/loader.mp4";

interface IProps {
  children: React.ReactNode;
}

function WithSuspense({ children }: IProps) {
  return (
    <Suspense
      fallback={
        <div className="mt-20 flex flex-col items-center">
          <Video videSource={Loader} autoplay loop width={124} />
          <span className="animate-[fade_1s_ease-in-out] font-medium my-2 mt-4">Loading...</span>
          <p className="text-12px">Please wait while we take you to the world of AI.</p>
        </div>
      }
    >
      {children}

      <BackToTop />
    </Suspense>
  );
}

export default WithSuspense;
