import { lazy } from "react";
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import Layout from "components/layouts/layout";

import ErrorBoundary from "routes/error-boundary";
import ProtectedRoutes from "routes/protected-routes";

import WithSuspense from "./with-suspense";

const Home = lazy(() => import("pages/home"));
const Try = lazy(() => import("pages/try"));
const PrivacyPolicy = lazy(() => import("pages/privacy-policy"));
const AppPrivacyPolicy = lazy(() => import("pages/app-privacy-policy"));
const KolClaims = lazy(() => import("pages/claims/kol-claims"));
const PublicClaim = lazy(() => import("pages/claims/public-claim"));
const Staking = lazy(() => import("pages/staking/staking"));
const StakeToken = lazy(() => import("pages/staking/stake-token"));
const NotFound = lazy(() => import("pages/NotFound"));

function Router() {
  const authRoutes = ProtectedRoutes();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Layout />} errorElement={<ErrorBoundary />}>
        <Route path="/" element={<Home />} />
        <Route path="/newsletter" element={<Home />} />
        <Route path="/try" element={<Try />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/kol-claim" element={<KolClaims />} />
        <Route path="/public-claim" element={<PublicClaim />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/stake-token" element={<StakeToken />} />
        <Route path="/app-privacy-policy" element={<AppPrivacyPolicy />} />

        <Route path="/not-found" element={<NotFound />} />

        <Route>{authRoutes}</Route>

        {/* Unknown path redirect */}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    )
  );

  return (
    <WithSuspense>
      <RouterProvider router={router} />
    </WithSuspense>
  );
}

export default Router;
