import { Navigate, Outlet, Route } from "react-router-dom";

import { useUserContext } from "context/user-context";

import AiModel from "pages/ai-model/ai-model";

function ProtectedRoot() {
  const { isUserLoggedIn } = useUserContext();
  if (!isUserLoggedIn) {
    return <Navigate to={`/?fallback=${window.location.pathname}`} replace />;
  }
  return <Outlet />;
}

function ProtectedRoutes() {
  return (
    <Route path="/" element={<ProtectedRoot />}>
      <Route path="/ai-model/:model" element={<AiModel />} />
    </Route>
  );
}

export default ProtectedRoutes;
