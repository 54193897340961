export default {
  chain: {
    name: "chain",
    requiredMessage: "Chain is required",
  },
  isSellNFT: {
    name: "isSellNFT",
  },
  isBuyNFT: {
    name: "isBuyNFT",
  },
  promptText: {
    name: "promptText",
  },
};
