import { useCallback, useState } from "react";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";

import clsx from "clsx";

import Button from "components/core-ui/button";

import DeleteIcon from "assets/icons/delete-icon.png";
import FileIcon from "assets/icons/file-icon.svg";
import UploadIcon from "assets/icons/upload-icon.png";

import ImagePreviewModal from "./image-preview-modal";

interface IProps {
  error: string[];
  name: string;
  previewFile: File | undefined;
  onChange: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
  files: File[];
  setFiles: (files: File[]) => void;
}

function UploadNft({ onChange, files, setFiles, error }: IProps) {
  const [currentPreviewKey, setCurrentPreviewKey] = useState<File | null>(null);

  const [currentModalPreviewKey, setCurrentModalPreviewKey] = useState<File | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

  return (
    <div className="mb-4">
      <Dropzone maxSize={100000000} onDrop={onChange} disabled={files.length > 9}>
        {({ getRootProps, getInputProps }) => (
          <section
            className={clsx(
              "border border-primary rounded-md flex items-center justify-center bg-no-repeat bg-cover bg-center h-48",
              files.length > 9 ? "cursor-not-allowed" : "cursor-pointer"
            )}
          >
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="text-center">
                <img className="mx-auto mb-2" src={UploadIcon} alt="Upload from computer" />
                <p className="text-18px">Drag and drop Images from computer</p>
                <u className="text-12px">100 mb Max File size allowed</u>
                {files.length > 9 && <p className="my-2 text-red-500 animate-fade-in">Max 10 files allowed</p>}
              </div>
            </div>
          </section>
        )}
      </Dropzone>

      {error && error.length && error.length > 0 ? (
        <p className="text-red-500 my-1 animate-fade-in">{error?.[0]}</p>
      ) : null}

      <div className="grid sm:grid-cols-2 3xl:grid-cols-3 gap-8 mt-4">
        {files &&
          files.map(file => (
            <button
              type="button"
              key={`${file.name}`}
              className="relative border border-gray-500 p-4 flex items-center gap-6 rounded-sm"
              onClick={() => {
                if (file.type.includes("image")) {
                  setCurrentModalPreviewKey(file);
                  toggleModal();
                  return;
                }

                window.open(URL.createObjectURL(file), "_blank");
              }}
            >
              <Button
                className="absolute right-2 top-2 z-10"
                contentClassName=""
                variant="text"
                onClick={e => {
                  e.stopPropagation();
                  setFiles(files.filter(f => f.name !== file.name));
                  setCurrentPreviewKey(null);
                }}
              >
                <img className="w-5 opacity-80" src={DeleteIcon} alt="" />
              </Button>
              <img className="w-4" src={FileIcon} alt="" />
              <p className="flex-1 truncate pe-3">{file.name}</p>

              {currentPreviewKey && currentPreviewKey.name === file.name && (
                <div className="absolute -right-16 bottom-16">
                  <img className="object-cover w-48 h-48" src={URL.createObjectURL(currentPreviewKey)} alt="" />
                </div>
              )}
            </button>
          ))}
      </div>

      <ImagePreviewModal file={currentModalPreviewKey} isOpen={isOpen} onClose={toggleModal} />
    </div>
  );
}

export default UploadNft;
