import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";

import { API_URL, QUERY_KEYS } from "constants/api";
import { DATE_FORMAT, TRADING_MODES_OPTIONS } from "constants/global";
import moment from "moment";

import Axios from "config/axios";

import Button from "components/core-ui/button";
import ErrorMessage from "components/core-ui/error-message/error-message";
import Select from "components/core-ui/select/select";
import Skeleton from "components/core-ui/skeleton/skeleton";

import useUpdateCredits from "hooks/api/useUpdateCredits";

import BuyIcon from "assets/icons/buy.png";
import GPTBotSm from "assets/icons/gpt-bot-sm.svg";
import SellIcon from "assets/icons/sell.png";

import { IAiModelProps } from "../IAiModel";
import AiModelMode from "../ai-model-mode";
import { IAiTradingIndicator } from "./IAiTradingIndicator";
import TradingViewWidget from "./trading-view";

function AiTradingIndicator({ id }: IAiModelProps) {
  const [cryptoSignals, setCryptoSignals] = useState([]);
  const [isModeBasic, setIsModeBasic] = useState(true);

  const [filter, setFilter] = useState({
    mode: "default",
  });

  const [currentSymbol, setCurrentSymbol] = useState("BTCUSD");

  const getCryptoSignals = () => Axios.get(API_URL.GET_CRYPTO_SIGNALS, {});
  const { mutate, data, isLoading, isError, reset, isSuccess, error } = useMutation(
    QUERY_KEYS.GET_CRYPTO_SIGNALS,
    getCryptoSignals
  );

  useEffect(() => {
    if (reset) {
      reset();
    }

    if (data) {
      setCryptoSignals(data?.data?.data?.data);
    }
  }, [data, reset]);

  const getFilteredCryptoSignals = useMemo(() => {
    if (filter.mode === "FUTURE") return [];

    if (filter.mode === "default") {
      return cryptoSignals;
    }
    return cryptoSignals.filter((signal: IAiTradingIndicator) => signal.feed === filter.mode) || [];
  }, [filter.mode, cryptoSignals]);

  useUpdateCredits(isSuccess, reset);

  return (
    <div>
      <AiModelMode
        isModeBasic={isModeBasic}
        setIsModeBasic={setIsModeBasic}
        basicTitle="AI Signals"
        advancedTitle="Expert Signals"
      />
      {isModeBasic ? (
        <div id={id} className="flex flex-col 2xl:flex-row gap-2">
          <div className="w-full 2xl:w-1/2">
            <div className="px-6 pt-8">
              <h2 className="text-20px font-medium text-primary">Trading Signals</h2>
              <p>
                Backed by financial experts & crypto analysts, Guru AI presents you the exclusive trading indicators for
                both Spot & Futures trading.
              </p>
            </div>
            <div className="flex justify-end my-2 mt-4">
              <Select
                size="sm"
                onChange={({ value }) => setFilter({ ...filter, mode: value })}
                options={TRADING_MODES_OPTIONS}
                defaultValue="default"
              />
            </div>

            <div className="bg-primary-100 rounded-lg px-6 py-12 flex flex-col gap-8 max-h-[600px] 2xl:h-[600px] overflow-auto">
              {isError && <ErrorMessage error={error} />}

              {filter.mode === "FUTURE" ? (
                <div className="h-full w-full flex items-center justify-center">
                  <p className="text-20px font-medium">
                    <span className="text-primary">Future</span> Trading Signals coming soon
                  </p>
                </div>
              ) : (
                <div>
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <div>
                      {getFilteredCryptoSignals.length > 0 ? (
                        getFilteredCryptoSignals &&
                        getFilteredCryptoSignals.map(
                          ({
                            _id,
                            pair,
                            createdAt,
                            direction,
                            entry,
                            feed,
                            stoploss,
                            targets,
                          }: IAiTradingIndicator) => (
                            <button
                              type="button"
                              onClick={() => {
                                setCurrentSymbol((pair.value1 + pair.value2).toString());
                              }}
                              key={_id}
                            >
                              <div className="flex items-start gap-4 mb-2">
                                <img className="w-[36px]" src={GPTBotSm} alt="GPT Guru trading signals bot" />
                                <div className="bg-primary-100 px-4 py-6 rounded-lg w-full grid grid-cols-2 gap-y-3">
                                  <p className="grid grid-cols-12 items-center">
                                    <span className="col-span-4 text-14px font-light">Trading Pair :</span>{" "}
                                    <span className="col-span-8 font-medium">
                                      {pair.value1}/{pair.value2}
                                    </span>
                                  </p>
                                  <p className="grid grid-cols-12 items-center">
                                    <span className="col-span-4 text-14px font-light">Entry Price:</span>{" "}
                                    <span className="col-span-8 font-medium">
                                      {entry.value1} - {entry.value2}
                                    </span>
                                  </p>
                                  <p className="grid grid-cols-12 items-center">
                                    <span className="col-span-4 text-14px font-light">Target 1:</span>{" "}
                                    <span className="col-span-8 font-medium">{targets.target1}</span>
                                  </p>
                                  <p className="grid grid-cols-12 items-center">
                                    <span className="col-span-4 text-14px font-light">Target 2:</span>{" "}
                                    <span className="col-span-8 font-medium">{targets.target2}</span>
                                  </p>
                                  <p className="grid grid-cols-12 items-center">
                                    <span className="col-span-4 text-14px font-light">Target 3:</span>{" "}
                                    <span className="col-span-8 font-medium">{targets.target3}</span>
                                  </p>

                                  <p className="grid grid-cols-12 items-center">
                                    <span className="col-span-4 text-14px font-light">Target 4:</span>{" "}
                                    <span className="col-span-8 font-medium">{targets.target4}</span>
                                  </p>
                                  <p className="grid grid-cols-12 items-center">
                                    <span className="col-span-4 text-14px font-light">Direction:</span>{" "}
                                    <div className="col-span-8 font-medium flex items-center justify-center">
                                      <span>{direction}</span>
                                      <img
                                        className="inline-block ms-2"
                                        src={direction === "BUY" ? BuyIcon : SellIcon}
                                        alt={direction}
                                      />{" "}
                                    </div>
                                  </p>
                                  <p className="grid grid-cols-12 items-center">
                                    <span className="col-span-4 text-14px font-light">Signal type:</span>{" "}
                                    <span className="col-span-8 font-medium">{feed}</span>
                                  </p>
                                  <p className="grid grid-cols-12 items-center">
                                    <span className="col-span-4 text-14px font-light">Stoploss: </span>
                                    <span className="col-span-8 font-medium">{stoploss}</span>
                                  </p>
                                </div>
                              </div>
                              <span className="block ms-auto w-max text-gray-500 text-14px">
                                {moment(createdAt).format(DATE_FORMAT) ?? ""}
                              </span>
                            </button>
                          )
                        )
                      ) : (
                        <div className="mx-auto my-20 text-center">
                          <Button loading={isLoading} onClick={() => mutate()} className="mx-auto mb-2">
                            Get Signals
                          </Button>
                          <p className="text-14px text-gray-400">
                            It&apos;ll cost one credit for each signal request.{" "}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="relative w-full 2xl:w-1/2 p-6 pt-12">
            <p className="text-18px mb-8 text-center">
              The GPT Guru AI trading model currently uses basic indicators including RSI, MCAD, MA, and EMA.
            </p>
            <div className="h-[575px]">
              <TradingViewWidget symbol={currentSymbol} />
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[500px] flex items-center justify-center">Expert Signals Coming Soon</div>
      )}
    </div>
  );
}

export default AiTradingIndicator;
