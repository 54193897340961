import { useState } from "react";

import { clsx } from "clsx";

import Button from "../button";
import { ICopyToClipboardProps } from "./IICopyToClipboardProps";

function CopyToClipboard({
  className = "",
  text = "Copy",
  children = null,
  onCopy = () => {},
  variant = "primary",
  backgroundColor = "var(--color-primary)",
  prefix,
  contentClassName = "px-4 sm:px-10 py-3",
  buttonClassName = "",
}: ICopyToClipboardProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(text);

    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 1500);

    onCopy(text);
  };

  return (
    <div className={clsx("relative", className)}>
      <Button
        contentClassName={contentClassName}
        prefix={prefix}
        backgroundColor={backgroundColor}
        onClick={handleClick}
        variant={variant}
        className={buttonClassName}
      >
        {children || text}
      </Button>
      {showTooltip && (
        <div className="absolute -top-12 animate-fade left-4 bg-white rounded-md shadow-md p-2 text-12px font-medium text-black">
          {showTooltip ? "Copied!" : "Copy to clipboard"}
        </div>
      )}
    </div>
  );
}

export default CopyToClipboard;
