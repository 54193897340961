/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import { clsx } from "clsx";
import { WHITEPAPER_LINK } from "constants/global";

import Banner from "components/banner/banner";
import AnchorLink from "components/core-ui/anchor-link";
import Button from "components/core-ui/button";
import SelectSecondary from "components/core-ui/select/select-secondary";

import { useUserContext } from "context/user-context";

import useViewportWidth, { useViewportHeight } from "hooks/use-viewport";

import BuyGptGModal from "pages/home/components/buy-gptg-modal";

import CloseIcon from "assets/icons/close.svg";
import HamburgerIcon from "assets/icons/hamburger.svg";
import LogoDark from "assets/images/logo-dark.png";

import Profile from "./profile";

function Header() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const handleWallet = location.pathname === "/app-privacy-policy";

  const navLinks = [
    {
      id: "features",
      name: "Features",
      dropdown: true,
      link: "/try",
      hideOnMobile: true,
    },
    {
      id: "whitepaper",
      name: "Whitepaper",
      link: WHITEPAPER_LINK,
      external: true,
    },
    {
      id: "public-claim",
      name: "Public Claim",
      link: "/public-claim",
      hideOnDesktop: true,
    },
    {
      id: "buy",
      name: "Buy GPTG",
      link: "/buy",
      component: true,
    },
    {
      id: "staking",
      name: "Staking",
      link: "/staking",
    },
    {
      id: "try-app",
      name: "Guru App",
      link: "/try",
    },
  ];

  const handleClickLink = (id: string) => {
    if (open) {
      setOpen(false);
    }
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isSmallViewport = useViewportHeight() < 726;

  const isTablet = useViewportWidth() < 992;

  useEffect(() => {
    if (!isTablet && open) {
      setOpen(false);
    }
  }, [isTablet, open]);

  const showMenu = () => setOpen(!open);

  const { logoutUser, isUserLoggedIn } = useUserContext();

  const filteredNavLinks = navLinks.filter(({ hideOnDesktop }) => !hideOnDesktop);

  return (
    !handleWallet && (
      <header className="[&>a]:underline [&>a]:mr-8 font-serif">
        <div className="flex items-center justify-between max-w-container px-[16px] sm:px-[24px] lg:px-[60px] xl:px-[80px] 3xl:px-[140px] py-5 lg:py-0">
          <button type="button" className="inline-block lg:hidden h-[50px] w-[60px]" onClick={showMenu}>
            <img className="mt-3" src={open ? CloseIcon : HamburgerIcon} alt="" />
          </button>
          <div className="flex items-center gap-8 xl:gap-12 py-2">
            <NavLink to="/" className="mr-4">
              <img src={LogoDark} className="w-[220px] lg:w-[200px] xl:w-[270px] 3xl:w-[290px]" alt="" />
            </NavLink>
            <div className="hidden lg:flex items-center gap-10">
              {filteredNavLinks &&
                Array.isArray(filteredNavLinks) &&
                filteredNavLinks.map(({ id, link, name, external, dropdown, component }) =>
                  external ? (
                    <AnchorLink
                      onClick={() => handleClickLink(id)}
                      className="text-14px xl:text-16px font-medium mt-2 hover:text-primary"
                      href={link}
                      key={id}
                    >
                      {name}
                    </AnchorLink>
                  ) : dropdown ? (
                    <SelectSecondary />
                  ) : component ? (
                    <BuyGptGModal variant="text" contentClassName="mt-2 hover:text-primary" />
                  ) : (
                    <NavLink
                      onClick={() => handleClickLink(id)}
                      className="text-14px xl:text-16px font-medium mt-2 hover:text-primary"
                      to={link}
                      key={id}
                    >
                      {name}
                    </NavLink>
                  )
                )}
            </div>
          </div>

          <div className="hidden lg:flex items-center my-1">
            <Profile />
          </div>

          <div className="block lg:hidden" />
        </div>

        <Banner />

        {open && (
          <div className="absolute right-0 z-10 bg-black w-full animate-fade-up border-b border-primary">
            <nav className="mt-5">
              <ul
                className={clsx(
                  "text-white flex flex-col items-center overflow-y-auto",
                  isSmallViewport ? "!h-[calc(100vh-140px)]" : "h-max"
                )}
              >
                {navLinks
                  .filter(({ hideOnMobile }) => !hideOnMobile)
                  .map(({ id, name, link, external, component }) => (
                    <div className="flex flex-col items-center gap-y-2 [&:last-child>span]:hidden mb-2" key={id}>
                      <li className="h-[40px] leading-[40px] hover:text-primary">
                        {external ? (
                          <AnchorLink onClick={() => handleClickLink(id)} href={link} key={id}>
                            {name}
                          </AnchorLink>
                        ) : component ? (
                          <BuyGptGModal variant="text" contentClassName="mt-2 hover:text-primary" />
                        ) : (
                          <Link to={link} onClick={() => handleClickLink(id)}>
                            <span>{name}</span>
                          </Link>
                        )}
                      </li>
                      <span className="inline-block h-0 w-5 border border-primary sm:mx-[10px] lg:mx-[16px] xl:mx-[20px] 1xl:mx-[30px] my-3" />
                    </div>
                  ))}

                <li className="pb-2">
                  <Profile />
                </li>

                {isUserLoggedIn && (
                  <li className="h-[40px] leading-[40px] hover:text-primary">
                    <Button variant="text" onClick={logoutUser}>
                      Logout
                    </Button>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        )}
      </header>
    )
  );
}

export default Header;
