import { useState } from "react";

import { Switch as SwitchComponent } from "@headlessui/react";
import { clsx } from "clsx";

import { ISwitchProps } from "./ISwitch";

function Switch({ className, label = "", checked, onChange }: ISwitchProps) {
  const [enabled, setEnabled] = useState(checked);

  return (
    <div className={className}>
      <p className={clsx(label && "mb-2")}>{label}</p>
      <SwitchComponent
        checked={enabled}
        onChange={e => {
          setEnabled(e);
          if (onChange) onChange(e);
        }}
        className={`${enabled ? "bg-primary" : "bg-secondary-100"}
           relative inline-flex h-[32px] w-[64px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${enabled ? "translate-x-9 bg-white" : "translate-x-0 bg-primary"}
            pointer-events-none inline-block h-[28px] w-[28px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </SwitchComponent>
    </div>
  );
}

export default Switch;
