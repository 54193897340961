import clsx from "clsx";

import { IInputProps, ITextAreaProps } from "./IInput";

function Input({
  type = "text",
  name = "",
  placeholder = "Type here",
  isValid,
  isInvalid,
  showError = true,
  onChange = () => {},
  onBlur = () => {},
  value = "",
  label,
  error,
  className = "",
  inputClassName = "",
  width = "100%",
  disabled = false,
  defaultValue,
}: IInputProps) {
  return (
    <div className={clsx(className)}>
      <label className={clsx("text-white block", label && "mb-2")} htmlFor={name}>
        <p className={clsx(label && "mb-2")}>{label}</p>
        <input
          name={name}
          className={clsx(
            "border outline-none border-primary px-8 py-4 rounded-lg text-white bg-transparent",
            isInvalid && "!border-danger-400",
            isValid && "!border-success-600",
            disabled && "opacity-50 cursor-not-allowed",
            inputClassName
          )}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value || defaultValue}
          style={{ width }}
          disabled={disabled}
          required
        />
      </label>
      {error && showError && <p className="text-red-600 animate-fade-in">{error}</p>}
    </div>
  );
}
function TextArea({
  name = "",
  placeholder = "Type here",
  isValid,
  isInvalid,
  showError = true,
  onChange = () => {},
  onBlur = () => {},
  value,
  label,
  error,
  minHeight = 350,
  width = "100%",
  defaultValue,
  maxLength,
}: ITextAreaProps) {
  return (
    <>
      <label className="text-white block mb-2 relative" htmlFor={name}>
        <p className="mb-2">{label}</p>
        <textarea
          name={name}
          className={clsx(
            "bg-transparent border outline-none border-primary px-8 py-4 rounded-md text-white resize-none",
            isInvalid && "!border-danger-400",
            isValid && "!border-success-600"
          )}
          style={{ minHeight, width }}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value || defaultValue}
          maxLength={maxLength}
        />
        {maxLength && (
          <p className="absolute bottom-2 right-2 text-white text-sm">
            {value?.length || defaultValue?.length || 0}/{maxLength}
          </p>
        )}
      </label>
      {error && showError && <p className="text-red-600 animate-fade-in">{error}</p>}
    </>
  );
}

Input.TextArea = TextArea;
export default Input;
