/* eslint-disable new-cap */

/* eslint-disable no-new */

/* eslint-disable @typescript-eslint/no-use-before-define */

/* eslint-disable no-return-assign */

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect, useRef } from "react";

let tvScriptLoadingPromise;

export default function TradingViewWidget({ symbol, isModeBasic = false }: { symbol: string; isModeBasic?: boolean }) {
  const onLoadScriptRef = useRef();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise(resolve => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (document.getElementById("tradingview_5c594") && "TradingView" in window) {
        new window.TradingView.widget({
          autosize: false,
          symbol,
          interval: "240",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          allow_symbol_change: !isModeBasic,
          container_id: "tradingview_5c594",
          hide_top_toolbar: isModeBasic,
          hide_legend: isModeBasic,
          save_image: !isModeBasic,
        });
      }
    }
  }, [isModeBasic, symbol]);

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_5c594" />
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow noreferrer" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
}
