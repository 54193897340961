import { useEffect, useState } from "react";
import { useMutation } from "react-query";

import { clsx } from "clsx";
import { API_URL, QUERY_KEYS } from "constants/api";
import { Field, Form, Formik } from "formik";
import Typewriter from "typewriter-effect";

import Axios from "config/axios";

import Button from "components/core-ui/button";
import CopyToClipboard from "components/core-ui/copy-to-clipboard/copy-to-clipboard";
import ErrorMessage from "components/core-ui/error-message/error-message";
import { Input } from "components/core-ui/input";
import Switch from "components/core-ui/switch";

import useUpdateCredits from "hooks/api/useUpdateCredits";

import CopyToClipboardIcon from "assets/icons/copy.svg";

import { getSuiCodeType } from "utils/get-sui-param";

import { IAiModelProps } from "../IAiModel";
import AiModelMode from "../ai-model-mode";
import DefaultScreen from "../components/default-screen";
import { IGetErc20Body } from "./ISUINetwork";
import formFields from "./form-fields";
import { formSchema } from "./form-schema";

function SUINetwork({ id }: IAiModelProps) {
  const [isModeBasic, setIsModeBasic] = useState(true);
  const [concatenatedResponse, setConcatenatedResponse] = useState();
  const [loader, setLoader] = useState(false);

  const { tokenName, tokenSupply, tokenSymbol, isMintable, isBurnable } = formFields;

  const getSuiNetwork = (params: IGetErc20Body) => {
    return Axios.get(API_URL.GET_SUI_TOKEN, {
      params: {
        name: getSuiCodeType(params.isMintable, params.isBurnable),
        ...params,
      },
    });
  };

  const { mutate, isLoading, data, isError, isSuccess, reset, error } = useMutation(
    QUERY_KEYS.GET_SUI_TOKENS,
    getSuiNetwork
  );

  useUpdateCredits(isSuccess, reset);

  useEffect(() => {
    if (data) {
      setConcatenatedResponse(data?.data?.result?.value);
    }
  }, [data]);

  return (
    <>
      <AiModelMode isModeBasic={isModeBasic} setIsModeBasic={setIsModeBasic} />
      <div id={id} className="xl:flex gap-2">
        <div className="w-full xl:w-1/2">
          <div className="px-6 py-8">
            <h2 className="text-20px font-medium text-primary">Generate smart contract</h2>
            <p>
              With Guru AI, you can generate a smart contract across sui network way fast and easily like never before.
            </p>
          </div>

          <div className="rounded-lg bg-primary-100 px-6 py-6 flex flex-col gap-8">
            <Formik
              initialValues={{
                tokenName: "",
                tokenSymbol: "",
                tokenSupply: "",
                chain: "sui",
                isMintable: true,
                isBurnable: true,
              }}
              validationSchema={formSchema[isModeBasic ? "basic" : "advanced"]}
              onSubmit={values => {
                setLoader(true);
                setConcatenatedResponse(undefined);
                setTimeout(() => {
                  mutate(values);
                  setLoader(false);
                }, 3000);
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  {isModeBasic ? (
                    <>
                      <Field
                        name={tokenName.name}
                        type="text"
                        error={errors.tokenName && touched.tokenName ? errors.tokenName : null}
                        label="Token name"
                        as={Input}
                        className="mb-8"
                        placeholder="Banana Hunters"
                      />
                      <Field
                        name={tokenSymbol.name}
                        type="text"
                        error={errors.tokenSymbol && touched.tokenSymbol ? errors.tokenSymbol : null}
                        label="Token symbol"
                        as={Input}
                        className="mb-8"
                        placeholder="$BNHN"
                      />
                      <Field
                        name={tokenSupply.name}
                        type="number"
                        error={errors.tokenSupply && touched.tokenSupply ? errors.tokenSupply : null}
                        label="Token supply"
                        as={Input}
                        className="mb-8"
                        placeholder="1000000000"
                      />

                      <div className="flex gap-16 mb-24">
                        <Switch label="Mintable" checked onChange={value => setFieldValue(isMintable.name, value)} />
                        <Switch label="Burnable" checked onChange={value => setFieldValue(isBurnable.name, value)} />
                      </div>
                    </>
                  ) : (
                    <div className="text-center my-20">Coming soon</div>
                  )}

                  {isModeBasic && (
                    <div className={clsx("flex items-center mt-16", isError ? "justify-between" : "justify-end")}>
                      {isError && <ErrorMessage error={error} />}
                      <Button
                        loading={isLoading || loader}
                        type="submit"
                        width={140}
                        backgroundColor="var(--color-primary)"
                      >
                        Go Guru
                      </Button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="flex-1 lg:w-full xl:w-1/2 bg-tertiary-100 py-8 px-6 rounded-lg">
          {concatenatedResponse ? (
            <>
              <div className="bg-primary- border border-primary-100 rounded-md h-[650px] overflow-auto p-4 whitespace-pre-wrap mb-8">
                <Typewriter
                  options={{
                    delay: 10,
                  }}
                  onInit={typewriter => {
                    typewriter.typeString(concatenatedResponse).start();
                  }}
                />
              </div>
              {concatenatedResponse && (
                <div className="flex gap-2">
                  <CopyToClipboard
                    text={concatenatedResponse}
                    prefix={<img className="w-7" src={CopyToClipboardIcon} alt="copy to clipboard" />}
                  >
                    <span className="font-medium">Copy</span>
                  </CopyToClipboard>
                  <Button onClick={() => setConcatenatedResponse(undefined)} variant="text" className="text-info">
                    Clear
                  </Button>
                </div>
              )}
            </>
          ) : (
            <DefaultScreen
              isLoading={isLoading || loader}
              className="my-32"
              description="Generate a smart contact on any network. "
            />
          )}
        </div>
      </div>
    </>
  );
}

export default SUINetwork;
