import { useEffect, useMemo, useState } from "react";

import { Address } from "abitype";
import { useAccount, useContractRead, useNetwork } from "wagmi";
import { ISecretMessage, NetworkType, TypeBigNumber } from "web3/IWeb3";
import { getContractABI, getContractAddress } from "web3/abiHelper";
import { parseBigNumber } from "web3/utils";

function useReadSecretMessage(total = { received: 5, sent: 5 }) {
  const [receivedMessages, setReceivedMessages] = useState<ISecretMessage[]>([]);
  const [sentMessages, setSentMessages] = useState<ISecretMessage[]>([]);

  const { chain } = useNetwork();
  const { address } = useAccount();

  const CHAIN_ID = chain?.id.toString() as NetworkType;
  const CONTRACT_ADDRESS = getContractAddress("GptGuruSecretMessaging", CHAIN_ID) as Address;
  const ABI = getContractABI("GptGuruSecretMessaging");

  const contract = {
    address: CONTRACT_ADDRESS,
    abi: ABI,
    args: [],
  };
  const overrides = {
    from: address,
  };

  // RECEIVED MESSAGES
  const {
    data: receivedMessageCount,
    isLoading: isLoadingReceivedMessageCount,
    refetch: refetchReceivedMessageCount,
    isRefetching: isRefetchingReceivedMessageCount,
    fetchStatus: fetchStatusReceivedMessageCount,
  } = useContractRead({
    ...contract,
    functionName: "getUserReceivedMessageCount",
    overrides,
  });

  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    if (fetchStatusReceivedMessageCount === "idle") {
      setCurrentIndex(receivedMessageCount ? parseBigNumber(receivedMessageCount as TypeBigNumber) - 1 : -1);
    } else if (fetchStatusReceivedMessageCount === "fetching") {
      setReceivedMessages([]);
    }
  }, [fetchStatusReceivedMessageCount, receivedMessageCount]);

  const { data: receivedMessageIdByIndex } = useContractRead({
    ...contract,
    functionName: "getUserReceivedMessageIdByIndex",
    args: [currentIndex],
    overrides,
    enabled: !!receivedMessageCount,
  });
  const {
    data: messageById,
    isSuccess: isSuccessMessageById,
    fetchStatus: fetchStatusMessageById,
  } = useContractRead({
    ...contract,
    functionName: "getMessageById",
    args: [receivedMessageIdByIndex],
    overrides,
    enabled: !!receivedMessageIdByIndex,
  });

  useEffect(() => {
    if (receivedMessageCount && messageById) {
      const receivedMessageCountData = parseBigNumber(receivedMessageCount as TypeBigNumber);

      if (
        (currentIndex === 0 || isSuccessMessageById) &&
        currentIndex >= 0 &&
        currentIndex >= receivedMessageCountData - total.received &&
        fetchStatusReceivedMessageCount === "idle"
      ) {
        const messageByIdData = messageById as ISecretMessage;

        setReceivedMessages([
          ...receivedMessages,
          {
            from: messageByIdData?.from,
            to: messageByIdData?.to,
            text: messageByIdData?.text,
            id: receivedMessageIdByIndex as string,
          },
        ]);
        setCurrentIndex(currentIndex - 1);
      }
    }
  }, [
    currentIndex,
    isSuccessMessageById,
    messageById,
    receivedMessages,
    receivedMessageCount,
    receivedMessageIdByIndex,
    total.received,
    fetchStatusReceivedMessageCount,
  ]);
  // SEND MESSAGES
  const {
    data: sentMessageCount,
    isLoading: isLoadingSentMessageCount,
    refetch: refetchSentMessageCount,
    isRefetching: isRefetchingSentMessageCount,
    fetchStatus: fetchStatusSentMessageCount,
  } = useContractRead({
    ...contract,
    functionName: "getUserSentMessageCount",
    overrides,
  });

  const [currentSentIndex, setCurrentSentIndex] = useState(-1);

  useEffect(() => {
    if (fetchStatusSentMessageCount === "idle") {
      setCurrentSentIndex(sentMessageCount ? parseBigNumber(sentMessageCount as TypeBigNumber) - 1 : -1);
    } else if (fetchStatusSentMessageCount === "fetching") {
      setSentMessages([]);
    }
  }, [fetchStatusSentMessageCount, sentMessageCount]);

  const { data: sentMessageIdByIndex } = useContractRead({
    ...contract,
    functionName: "getUserSentMessageIdByIndex",
    args: [currentSentIndex],
    overrides,
    enabled: !!sentMessageCount,
  });
  const { data: messageSentById, isSuccess: isSuccessMessageSentById } = useContractRead({
    ...contract,
    functionName: "getMessageById",
    args: [sentMessageIdByIndex],
    overrides,
    enabled: !!sentMessageIdByIndex,
  });

  useEffect(() => {
    if (sentMessageCount && messageSentById) {
      const sentMessageCountData = parseBigNumber(sentMessageCount as TypeBigNumber);

      if (
        (currentSentIndex === 0 || isSuccessMessageSentById) &&
        currentSentIndex >= 0 &&
        currentSentIndex >= sentMessageCountData - total.sent &&
        fetchStatusSentMessageCount === "idle"
      ) {
        const messageSentByIdData = messageSentById as ISecretMessage;

        setSentMessages([
          ...sentMessages,
          {
            from: messageSentByIdData?.from,
            to: messageSentByIdData?.to,
            text: messageSentByIdData?.text,
            id: sentMessageIdByIndex as string,
          },
        ]);
        setCurrentSentIndex(currentSentIndex - 1);
      }
    }
  }, [
    sentMessageCount,
    isSuccessMessageSentById,
    currentSentIndex,
    messageSentById,
    sentMessages,
    sentMessageIdByIndex,
    total.sent,
    fetchStatusSentMessageCount,
  ]);

  const refetchData = async () => {
    refetchReceivedMessageCount();
    refetchSentMessageCount();
  };

  const { data: maxMessageLengthData } = useContractRead({
    ...contract,
    functionName: "getMessageContractInfo",
    args: [],
    overrides,
  });

  const maxMessageLength = useMemo(() => {
    if (maxMessageLengthData) {
      return parseBigNumber(maxMessageLengthData as TypeBigNumber);
    }
    return null;
  }, [maxMessageLengthData]);

  return {
    isLoading: isLoadingReceivedMessageCount || isLoadingSentMessageCount,
    isRefetching: isRefetchingReceivedMessageCount || isRefetchingSentMessageCount,
    refetch: refetchData,
    values: {
      receivedMessages,
      receivedMessageCount: receivedMessageCount ? parseBigNumber(receivedMessageCount as TypeBigNumber) : null,
      sentMessages,
      sentMessageCount: sentMessageCount ? parseBigNumber(sentMessageCount as TypeBigNumber) : null,
    },
    fetchStatusReceivedMessageCount,
    fetchStatusSentMessageCount,
    maxMessageLength,
    fetchStatusMessageById,
  };
}

export default useReadSecretMessage;
