import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { BSC_CHAIN_ID } from "constants/global";
// import { BSC_CHAIN_ID } from "constants/global";
import { useAccount, useConnect, useNetwork, useSwitchNetwork } from "wagmi";

import Axios from "config/axios";

import { IContextValues, ICurrentUser } from "./IUserContext";

const userContext = createContext({} as IContextValues);

export const useUserContext = () => useContext(userContext);

const currentUserFromLocalStorage = JSON.parse(localStorage.getItem("__CURRENT_USER__") as string) || null;
const tokenFromLocalStorage = localStorage.getItem("__ACCESS_TOKEN__") || null;

function UserContextProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<ICurrentUser | null>(currentUserFromLocalStorage);
  const [userToken, setUserToken] = useState(tokenFromLocalStorage);

  const { isConnected, connector, address } = useAccount();
  const { connect, connectors } = useConnect();

  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const loginUser = useCallback(async (token: string, user: ICurrentUser) => {
    // Injecting token to all requests

    await Axios.interceptors.request.use(config => {
      const changedConfig = config;

      if (token && config.headers) {
        changedConfig.headers.Authorization = `Bearer ${token}`;
      }

      return changedConfig;
    });

    // Saving token to local storage
    setUserToken(token);
    setCurrentUser(user);

    localStorage.setItem("__ACCESS_TOKEN__", token);
    localStorage.setItem("__CURRENT_USER__", JSON.stringify(user));
  }, []);

  const isUserLoggedIn = !!userToken && !!address;

  const logoutUser = useCallback(async () => {
    localStorage.removeItem("__ACCESS_TOKEN__");
    localStorage.removeItem("__CURRENT_USER__");

    setUserToken(null);
    setCurrentUser(null);

    window.location.reload();
  }, []);

  const updateCreditInStorage = useCallback(() => {
    if (currentUser) {
      const updatedUser = {
        ...currentUser,
        credits: currentUser.credits ? currentUser.credits - 1 : 0,
      };

      setCurrentUser(updatedUser);
      localStorage.setItem("__CURRENT_USER__", JSON.stringify(updatedUser));
    }
  }, [currentUser]);

  const updateWhitelistingPoints = useCallback(
    (points: number) => {
      if (currentUser) {
        const updatedUser = {
          ...currentUser,
          whitelistingPoints: points,
        };

        setCurrentUser(updatedUser);
        localStorage.setItem("__CURRENT_USER__", JSON.stringify(updatedUser));
      }
    },
    [currentUser]
  );

  const values = useMemo(
    () => ({
      loginUser,
      isUserLoggedIn,
      logoutUser,
      userToken,
      currentUser,
      updateCreditInStorage,
      updateWhitelistingPoints,
    }),
    [loginUser, isUserLoggedIn, logoutUser, userToken, currentUser, updateCreditInStorage, updateWhitelistingPoints]
  );

  useEffect(() => {
    if (!isConnected && isUserLoggedIn) {
      connect({ connector: connectors[0] });
    }

    if (connector) {
      connector.on("change", () => {
        if (chain && isUserLoggedIn && chain.id !== BSC_CHAIN_ID && switchNetwork) {
          switchNetwork(BSC_CHAIN_ID);
        }

        if (address && currentUser && address !== currentUser?.publicAddress) {
          logoutUser();
        }
      });
    }
  }, [
    address,
    chain,
    connect,
    connector,
    connectors,
    currentUser,
    isConnected,
    isUserLoggedIn,
    logoutUser,
    switchNetwork,
  ]);

  return <userContext.Provider value={values}>{children}</userContext.Provider>;
}

export default UserContextProvider;
