import * as yup from "yup";

import formFields from "./form-fields";

const { tokenName, tokenSupply, tokenSymbol, promptText } = formFields;

export const formSchema = {
  basic: yup.object().shape({
    [tokenName.name]: yup.string().required(tokenName.requiredMessage),
    [tokenSymbol.name]: yup.string().required(tokenSymbol.requiredMessage),
    [tokenSupply.name]: yup.string().required(tokenSupply.requiredMessage),
  }),
  advanced: yup.object().shape({
    [promptText.name]: yup.string(),
  }),
};
