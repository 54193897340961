import { useParams } from "react-router-dom";

import clsx from "clsx";
import { useAccount } from "wagmi";

import { useUserContext } from "context/user-context";

import NotFound from "pages/NotFound";

import AiTradingIndicatorIcon from "assets/icons/ai-trading-indicator.svg";
import AiTrainingIcon from "assets/icons/ai-training.svg";
import GPTGuruChatIcon from "assets/icons/chatbot-icon.svg";
import ContentCreatorIcon from "assets/icons/content-creator.svg";
import ImageRendererIcon from "assets/icons/image-renderer.svg";
import MintNFTIcon from "assets/icons/mint-icon.svg";
import NFTMarketplaceIcon from "assets/icons/nft-marketplace.svg";
import SecretMessagingIcon from "assets/icons/secret-message-icon.svg";
import SmartContractAuditorIcon from "assets/icons/smart-contract-auditor.svg";
import SmartContractIcon from "assets/icons/smart-contracts.svg";
import SUINetworkIcon from "assets/icons/sui-network.svg";
import Web3DevelopmentIcon from "assets/icons/web3-development.svg";

import { truncateText } from "utils/truncate-text";

import AiTradingIndicator from "./ai-trading-indicator/ai-trading-indicator";
import AiTraining from "./ai-training/ai-training";
import ChatBot from "./chat-bot/chat-bot";
import { IAiModelItemProps } from "./components/IAiModelProps";
import AiModelItem from "./components/ai-model-item";
import ContentCreator from "./content-creator/content-creator";
import ImageRenderer from "./image-renderer/image-renderer";
import MintNFT from "./mint-nft/mint-nft";
import NFTMarketplace from "./nft-marketplace/nft-marketplace";
import SecretMessaging from "./secret-messaging/secret-messaging";
import SmartContractAuditor from "./smart-contract-auditor/smart-contract-auditor";
import SmartContracts from "./smart-contracts/smart-contracts";
import SuiNetwork from "./sui-network/sui-network";
import Web3Development from "./web3-development/web3-development";

function AiModel() {
  const { address } = useAccount();

  const aiModels = [
    {
      id: "ai-training",
      title: "AI Training",
      icon: AiTrainingIcon,
      url: "ai-training",
    },
    {
      id: "ai-trading-indicator",
      title: "AI Trading Indicator",
      icon: AiTradingIndicatorIcon,
      url: "ai-trading-indicator",
    },
    {
      id: "smart-contracts",
      title: "Smart Contracts",
      icon: SmartContractIcon,
      url: "smart-contracts",
    },
    {
      id: "smart-contract-auditor",
      title: "Smart Contract Auditor",
      icon: SmartContractAuditorIcon,
      url: "smart-contract-auditor",
    },
    {
      id: "chat-bot",
      title: "GPT Guru Chat",
      icon: GPTGuruChatIcon,
      url: "chat-bot",
    },
    {
      id: "sui-network",
      title: "SUI Network",
      icon: SUINetworkIcon,
      url: "sui-network",
    },
    {
      id: "nft-marketplace",
      title: "NFT Marketplace",
      icon: NFTMarketplaceIcon,
      url: "nft-marketplace",
    },
    {
      id: "mint-nft",
      title: "Mint NFT",
      icon: MintNFTIcon,
      url: "mint-nft",
    },
    {
      id: "secret-messaging",
      title: "Secret Messaging",
      icon: SecretMessagingIcon,
      url: "secret-messaging",
      isNew: true,
    },
  ];

  const sidekicks = [
    {
      id: "web3-development",
      title: "Web 3.0 Development",
      icon: Web3DevelopmentIcon,
      url: "web3-development",
      comingSoon: true,
    },
    {
      id: "content-creator",
      title: "Content Creator",
      icon: ContentCreatorIcon,
      url: "content-creator",
      comingSoon: true,
    },
    {
      id: "image-renderer",
      title: "Image Renderer",
      icon: ImageRendererIcon,
      url: "image-renderer",
      comingSoon: true,
    },
  ];

  const { model } = useParams();

  function GetContent() {
    switch (model) {
      case "smart-contracts":
        return <SmartContracts id={model} />;
      case "ai-trading-indicator":
        return <AiTradingIndicator id={model} />;
      case "image-renderer":
        return <ImageRenderer />;
      case "smart-contract-auditor":
        return <SmartContractAuditor id={model} />;
      case "web3-development":
        return <Web3Development />;
      case "nft-marketplace":
        return <NFTMarketplace id={model} />;
      case "content-creator":
        return <ContentCreator />;
      case "ai-training":
        return <AiTraining id={model} />;
      case "sui-network":
        return <SuiNetwork id={model} />;
      case "chat-bot":
        return <ChatBot id={model} />;
      case "mint-nft":
        return <MintNFT id={model} />;
      case "secret-messaging":
        return <SecretMessaging id={model} />;
      default:
        return <NotFound />;
    }
  }

  const { currentUser } = useUserContext();

  return (
    <div className="lg:flex gap-1">
      <div className="lg:min-h-screen w-full lg:w-[260px] xl:w-[420px] 3xl:w-[480px] bg-gradient-to-b from-tertiary-100 to-secondary-100 rounded-lg py-10 pt-4">
        <div className="px-6">
          <div className="flex justify-between gap-4">
            <p>
              <span className={clsx("w-2 h-2 inline-block rounded-full", address ? "bg-success-500" : "bg-red-600")} />{" "}
              {address ? (
                <>
                  Connected <span className="text-12px">({truncateText(address, 12, true)})</span>{" "}
                </>
              ) : (
                "Wallet not connected"
              )}
            </p>
            <h1 className="text-end text-12px mb-2">
              <span className="font-medium me-2 text-success-500">Credits Left:</span>{" "}
              <span>{currentUser?.credits || "0"}</span>{" "}
            </h1>
          </div>
        </div>

        <div className="px-6">
          <h1 className="mt-4 text-20px font-medium text-primary">Select AI Model!</h1>
          <p className="text-14px mb-3">
            Select your topic of interest and start asking GPT Guru in the left right box.
          </p>
        </div>

        <div className="px-6">
          <h2 className="mb-3 mt-4">
            🔥 <span className="text-gray-500">Top Trending</span>
          </h2>

          <div className="flex flex-wrap">
            {aiModels.map(({ id, icon, title, url, comingSoon, isNew }: IAiModelItemProps) => (
              <AiModelItem
                comingSoon={comingSoon}
                activeId={model}
                key={id}
                icon={icon}
                id={id}
                title={title}
                url={url}
                isNew={isNew}
              />
            ))}
          </div>

          <h2 className="mb-3 mt-6">
            💯 <span className="text-gray-500">Side Kicks</span>
          </h2>

          <div className="flex flex-wrap">
            {sidekicks.map(({ id, icon, title, url, comingSoon }: IAiModelItemProps) => (
              <AiModelItem
                comingSoon={comingSoon}
                activeId={model}
                key={id}
                icon={icon}
                id={id}
                title={title}
                url={url}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="px-6 flex-1">{GetContent()}</div>
    </div>
  );
}

export default AiModel;
