import Select from "components/core-ui/select/select";

import BscNetworkIcon from "assets/icons/bsc-icon.svg";
import EthNetworkIcon from "assets/icons/eth-icon.svg";
import SolIcon from "assets/icons/sol-icon.svg";
import SuiIcon from "assets/icons/sui.svg";

import { ISelectChain } from "./ISelectChain";

function SelectChain({ onChange, defaultValue = "erc-20", disabledOptionKeys = [] }: ISelectChain) {
  const chains = [
    {
      value: "erc-20",
      label: (
        <div className="flex items-center gap-4">
          <img width={18} src={EthNetworkIcon} alt="" /> Ethereum
        </div>
      ),
    },
    {
      value: "bep-20",
      label: (
        <div className="flex items-center gap-4">
          <img width={18} src={BscNetworkIcon} alt="" /> Binance Smart Chain
        </div>
      ),
    },
    {
      value: "spl",
      label: (
        <div className="flex items-center gap-4">
          <img width={18} src={SolIcon} alt="" /> Solana
        </div>
      ),
    },
    {
      value: "sui",
      label: (
        <div className="flex items-center gap-4">
          <img width={18} src={SuiIcon} alt="" /> SUI Network
        </div>
      ),
      disabled: true,
    },
  ];

  const filteredChains = chains.map(chain => {
    if (disabledOptionKeys.includes(chain.value)) {
      return {
        ...chain,
        disabled: true,
      };
    }

    return chain;
  });

  return (
    <Select
      onChange={({ value }) => onChange(value)}
      label="Chains"
      options={filteredChains}
      defaultValue={defaultValue}
      className="mb-6"
    />
  );
}

export default SelectChain;
