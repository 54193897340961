import { Fragment, useCallback, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { BIT_KEEP_CURRENT_PRICE_LINK, GPTG_TOKEN_CONTRACT, PANCAKE_SWAP_CURRENT_PRICE_LINK } from "constants/global";

import Button from "components/core-ui/button";
import { IButtonProps } from "components/core-ui/button/IButton";
import CopyToClipboard from "components/core-ui/copy-to-clipboard/copy-to-clipboard";

import TopRightIcon from "assets/icons/arrow-top-right.svg";
import CloseIcon from "assets/icons/close-icon.svg";
import CopyIcon from "assets/icons/copy.svg";
import BitKeepLogo from "assets/images/bit-keep-logo.png";
import PancakeSwapLogo from "assets/images/pancake-swap-logo.svg";

import { truncateText } from "utils/truncate-text";

function BuyGptGModal({ fontSize, variant = "white-outline", contentClassName = "py-3 px-8" }: IButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

  const exchangesData = [
    {
      id: "pancake-swap",
      image: PancakeSwapLogo,
      title: "Buy $GPTG from Pancake swap",
      url: PANCAKE_SWAP_CURRENT_PRICE_LINK,
    },
    {
      id: "bit-keep",
      image: BitKeepLogo,
      title: "Buy $GPTG from Pancake swap",
      url: BIT_KEEP_CURRENT_PRICE_LINK,
    },
  ];

  return (
    <>
      <Button
        fontSize={fontSize}
        onClick={toggleModal}
        variant={variant}
        className="font-normal"
        contentClassName={contentClassName}
      >
        Buy $GPTG
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={toggleModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative inline-block overflow-hidden text-left align-middle transition-all transform bg-black text-white shadow-xl rounded-sm w-full lg:w-[880px] py-[60px] px-[12px] md:px-[20px] lg:px-[40px] border border-gray-600">
                <Dialog.Title as="h3" className="mb-3 leading-6 text-center text-40px">
                  Buy $GPTG
                </Dialog.Title>
                <p className="text-center  font-light text-20px mb-10">Select an exchange to Buy $GPTG.</p>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {exchangesData.map(({ id, image, title, url }) => (
                    <a key={id} href={url} target="_blank" rel="noreferrer" className="block">
                      <div className="relative h-[145px] bg-black-300 py-4 px-3 flex items-center justify-center">
                        <img className="absolute top-4 right-3" src={TopRightIcon} alt="" />
                        <img className="p-1" src={image} alt={title} />
                      </div>
                    </a>
                  ))}
                </div>

                <div className="mt-12">
                  <p className="text-20px md:text-24px font-light text-center px-2 lg:flex items-center justify-center gap-2">
                    <span>$GPTG Token Contract Address: </span>
                    <div className="flex items-center justify-center">
                      <span className="font-medium">{truncateText(GPTG_TOKEN_CONTRACT, 16, true)}</span>{" "}
                      <CopyToClipboard
                        variant="primary-outline"
                        className="ms-4"
                        contentClassName="inline-block p-2"
                        text={GPTG_TOKEN_CONTRACT}
                      >
                        <img src={CopyIcon} alt="Copy contract address to clipboard" />
                      </CopyToClipboard>
                    </div>
                  </p>
                </div>

                <Button
                  onClick={toggleModal}
                  className="absolute right-0 top-0"
                  contentClassName="px-4 py-6"
                  variant="text"
                >
                  <img src={CloseIcon} alt="close modal" />
                </Button>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default BuyGptGModal;
