import * as yup from "yup";

import formFields from "./form-fields";

const { chain, promptText } = formFields;

export const formSchema = {
  basic: yup.object().shape({
    [chain.name]: yup.string().required(chain.requiredMessage),
  }),
  advanced: yup.object().shape({
    [promptText.name]: yup.string(),
  }),
};
