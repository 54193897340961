import { useEffect } from "react";
import { useMutation } from "react-query";

import { API_URL, QUERY_KEYS } from "constants/api";

import Axios from "config/axios";

import { useUserContext } from "context/user-context";

function useUpdateCredits(successMutation: boolean, resetMutation: () => void) {
  const { updateCreditInStorage } = useUserContext();
  const updateCredits = () => Axios.patch(API_URL.UPDATE_CREDITS);

  const { mutate, isLoading, isSuccess, reset } = useMutation(QUERY_KEYS.UPDATE_CREDITS, updateCredits);

  useEffect(() => {
    if (successMutation) {
      mutate();
      resetMutation();
    }
    if (isSuccess) {
      updateCreditInStorage();
      reset();
    }
  }, [isSuccess, mutate, reset, resetMutation, successMutation, updateCreditInStorage]);

  return { updateCredit: mutate, isLoading, isSuccess };
}

export default useUpdateCredits;
