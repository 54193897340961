export default {
  tokenName: {
    name: "tokenName",
    requiredMessage: "Token name is required",
  },
  tokenSymbol: {
    name: "tokenSymbol",
    requiredMessage: "Token symbol is required",
  },
  tokenSupply: {
    name: "tokenSupply",
    requiredMessage: "Token supply is required",
  },
  chain: {
    name: "chain",
    requiredMessage: "Chain is required",
  },
  isBurnable: {
    name: "isBurnable",
  },
  isMintable: {
    name: "isMintable",
  },
  promptText: {
    name: "promptText",
  },
};
