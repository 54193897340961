import { clsx } from "clsx";

import DotLoader from "components/core-ui/loader/dot-loader";

import LoadingIcon from "assets/images/mint-nft-default.png";

interface IDefaultScreeProps {
  isLoading: boolean;
  className?: string;
}

function DefaultScreen({ isLoading, className = "" }: IDefaultScreeProps) {
  return (
    <div className={clsx("flex items-center justify-center", className)}>
      <div className="text-center">
        <img src={LoadingIcon} alt="" className="mx-auto mb-4" />
        {isLoading ? (
          <>
            <DotLoader />
            <p className="text-12px animate-fade-in-out mt-2">It might take a while, please wait.</p>
          </>
        ) : (
          <p>Mint NFTs like never before.</p>
        )}
      </div>
    </div>
  );
}

export default DefaultScreen;
