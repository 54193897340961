import { FormEvent, useEffect, useState } from "react";

import { SHEET_BEST_API } from "constants/global";

import Button from "components/core-ui/button";
import { Input } from "components/core-ui/input";

function Subscribe() {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (value && !loading) {
      setLoading(true);
      fetch(SHEET_BEST_API, {
        method: "POST",
        body: JSON.stringify([{ email: value }]),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(() => {
          setLoading(false);
          setIsSuccess(true);
          setValue("");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
        setValue("");
      }, 3000);
    }
  }, [isSuccess]);

  return (
    <div className="mt-20 lg:mt-32" id="newsletter">
      <div className="max-w-container px-[16px] sm:px-[24px] lg:px-[60px] xl:px-[80px] 3xl:px-[140px] relative">
        <div className="absolute top-0 centered-x -z-10 bg-gradient-to-b to-primary-blue-400 from-tertiary-400 px-3 sm:px-6 lg:px-14 py-8 lg:py-16 xl:py-20 text-center h-full w-[93%] lg:w-[60%] xl:w-[900px] mx-auto rounded-md blur-xl" />
        <div className="px-3 sm:px-6 lg:px-14 py-8 lg:py-12 xl:py-14 text-center min-h-[160px] lg:w-4/6 xl:w-[900px] mx-auto rounded-3xl bg-primary-dark-blue-900">
          <h3 className="font-medium text-34px lg:text-40px">Subscribe To Guru Newsletter 📰</h3>

          <p className="text-16px lg:text-20px mb-8 xl:w-3/4 mx-auto font-light">
            Subscribe to GPT Guru&apos;s official newsletter to never miss what Guru is coming up with for all of you ✨
          </p>

          <form onSubmit={handleSubmit}>
            <div className="flex justify-center">
              <Input
                className="w-full xl:w-[300px]"
                value={value}
                onChange={handleInput}
                type="email"
                placeholder="Enter email"
                inputClassName="border-white"
              />
              <Button
                width={160}
                type="submit"
                loading={loading}
                className="text-16px lg:text-20px -mx-2"
                fontWeight={500}
                borderRadius={6}
              >
                Subscribe
              </Button>
            </div>
          </form>
          {isSuccess && <p className="text-14px mt-4 animate-fade-in">Thank you for subscribing to our newsletter.</p>}
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
