import { ILink } from "./IAnchorLink";

function AnchorLink({ href = "/", className, target = "_blank", onClick, children }: ILink) {
  return (
    <a onClick={onClick} target={target} href={href} className={className} rel="noreferrer">
      {children}
    </a>
  );
}

export default AnchorLink;
