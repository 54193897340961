import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RainbowKitProvider, connectorsForWallets, darkTheme } from "@rainbow-me/rainbowkit";
import { metaMaskWallet, trustWallet } from "@rainbow-me/rainbowkit/wallets";
import AOS from "aos";
import { WagmiConfig, configureChains, createClient } from "wagmi";
import { bsc, bscTestnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

import ThemeContextProvider from "context/theme-context";
import UserContextProvider from "context/user-context";

import Router from "routes/router";

import "@rainbow-me/rainbowkit/styles.css";
import "aos/dist/aos.css";

// wagmi
const { chains, provider, webSocketProvider } = configureChains([bsc, bscTestnet], [publicProvider()]);
const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [metaMaskWallet({ chains }), trustWallet({ chains })],
  },
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});
// react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      retry: 0,
    },
    mutations: {
      retry: 0,
      retryDelay: 1000,
    },
  },
});

// aos
AOS.init();

function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <UserContextProvider>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider
              appInfo={{
                appName: "Charge Swap",
              }}
              chains={chains}
              theme={darkTheme({
                accentColor: "#7E7EFD",
                accentColorForeground: "white",
                borderRadius: "large",
                fontStack: "system",
                overlayBlur: "small",
              })}
            >
              <ThemeContextProvider>
                <Router />
                <ToastContainer closeButton={false} closeOnClick hideProgressBar />
              </ThemeContextProvider>
            </RainbowKitProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </UserContextProvider>
    </WagmiConfig>
  );
}

export default App;
