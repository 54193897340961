import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "constants/global";
import {
  Address,
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { NetworkType } from "web3/IWeb3";
import { getContractABI, getContractAddress } from "web3/abiHelper";
import { getWagmiErrorReason } from "web3/utils";

import useReadSecretMessage from "./useReadSecretMessage";

function useWriteSecretMessage() {
  const [formValues, setFormValues] = useState({
    receiver: "",
    message: "",
    encryptedResult: "",
  });

  const { chain } = useNetwork();
  const CHAIN_ID = chain?.id.toString() as NetworkType;

  const SECRET_MESSAGING_CONTRACT = getContractAddress("GptGuruSecretMessaging", CHAIN_ID) as Address;
  const SECRET_MESSAGING_ABI = getContractABI("GptGuruSecretMessaging");

  const { address } = useAccount();

  const { refetch } = useReadSecretMessage();

  const isValidReceiverAddress = useMemo(() => {
    return formValues.receiver && formValues.receiver.length === 42;
  }, [formValues.receiver]);

  const { config: writeSendMessageConfig, isLoading: iswriteSendMessagePrepareLoading } = usePrepareContractWrite({
    address: SECRET_MESSAGING_CONTRACT,
    abi: SECRET_MESSAGING_ABI,
    functionName: "sendMessage",
    args: [formValues.encryptedResult, formValues.receiver],
    enabled: Boolean(address && isValidReceiverAddress && formValues.message && formValues.encryptedResult),
  });

  const {
    data: writeSendMessageData,
    write: writeSendMessageWrite,
    isLoading: isLoadingWriteSendMessage,
    reset: resetWriteSendMessage,
  } = useContractWrite(writeSendMessageConfig);
  const {
    isLoading: isWriteSendMessageTxLoading,
    isSuccess: isWriteSendMessageTxSuccess,
    isError: isWriteSendMessageTxError,
    error: writeSendMessageTxError,
  } = useWaitForTransaction({
    hash: writeSendMessageData?.hash,
  });

  useEffect(() => {
    if (isWriteSendMessageTxSuccess) {
      toast.success(SUCCESS_MESSAGES["SAVE-TEXT"]);
      resetWriteSendMessage();
      refetch();
      setFormValues({
        ...formValues,
        message: "",
        encryptedResult: "",
      });
    }
    if (isWriteSendMessageTxError) {
      toast.error(getWagmiErrorReason(writeSendMessageTxError) || ERROR_MESSAGES["SAVE-TEXT"]);
    }
  }, [
    formValues,
    isWriteSendMessageTxError,
    isWriteSendMessageTxSuccess,
    refetch,
    resetWriteSendMessage,
    writeSendMessageTxError,
  ]);

  return {
    writeSendMessageWrite,
    isLoadingWriteSendMessage:
      isLoadingWriteSendMessage || isWriteSendMessageTxLoading || iswriteSendMessagePrepareLoading,
    formValues,
    setFormValues,
    isWriteSendMessageTxSuccess,
    resetWriteSendMessage,
  };
}

export default useWriteSecretMessage;
