export interface IGetSmartContractPrompt {
  tokenName: string;
  tokenSymbol: string;
  tokenSupply: string;
  tokenType: string;
  isMintable: boolean;
  isBurnable: boolean;
  language: string;
}
export interface IGetSmartContractNFTMarketplacePrompt {
  language: string;
  isBuyNFT: boolean;
  isSellNFT: boolean;
}

export function GetSmartContractPrompt({
  tokenName,
  tokenSupply,
  tokenSymbol,
  tokenType,
  isMintable,
  isBurnable,
  language,
}: IGetSmartContractPrompt) {
  return `Create a smart contract for an ${tokenType} token. The token name is ${tokenName}, the token symbol is ${tokenSymbol}, and the token supply is ${tokenSupply}. The token is ${
    isMintable ? "" : "not"
  } mintable and  ${isBurnable ? "" : "not"} burnable. Generate code in ${language}`;
}

export function GetSmartContractNFTMarketplacePrompt({
  language,
  isSellNFT,
  isBuyNFT,
}: IGetSmartContractNFTMarketplacePrompt) {
  return `Create a smart contract code for an NFT Marketplace. The smart contract include ${
    isSellNFT ? "Sell NFT," : ""
  } ${isBuyNFT ? "Buy NFT" : ""} function.  Generate code in ${language}`;
}
